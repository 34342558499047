import React, { useState, useEffect } from 'react'
import classes from "./adminTerms.module.css"
import { request } from '../../../util/fetchAPI';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import Modal from 'react-modal';


const AdminTerms = () => {


    const { token, user } = useSelector((state) => state.auth)
    const [termsAndConditions, setTermsAndConditions] = useState([]);
    const [currentType, setCurrentType] = useState("")
    const [currentWord, setCurrentWord] = useState("")
    const [id, setId] = useState("")
    const [showFormPreview, setShowFormPreview] = useState(false)


    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }

    const handleTypeChange = (e) => {


        if (currentType === "" || (listItems.length === 0 && currentWord === "")) {
            setCurrentType(e.target.value);

        }


        else {
            alert("You have already provided text for a different element type! Delete this text and then change the type.")
        }


    };



    const fetchTermsAndConditions = async () => {
        const data = await request(`/termsAndConditions/getAll`, 'GET');
        setTermsAndConditions(data[0].contents);
        setId(data[0]._id)
        console.log(data[0]);

    };


    useEffect(() => {
        fetchTermsAndConditions()


    }, [])



    const handleAddItem = () => {


        let newItem = {}

        if (currentType !== "") {


            if (currentType === "List" || currentType === "NumberedList") {

                newItem = {
                    elementType: currentType,
                    content: listItems
                }

            } else {

                newItem = {
                    elementType: currentType,
                    content: [{ contentString: currentWord }]
                }

            }



            setTermsAndConditions([...termsAndConditions, newItem]);

            setCurrentWord("");
            setListItems([])

            setCurrentType("");
        } else {
            alert("You must set an element type")
        }


    }



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(termsAndConditions);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setTermsAndConditions(updatedItems);
    };


    const deleteItem = (index) => {
        const items = [...termsAndConditions];
        items.splice(index, 1);
        setTermsAndConditions(items);
    };




    const handleUpdateTerms = async (e) => {



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;


            data = await request(`/termsAndConditions/${id}/`, 'PUT', options, {

                contents: termsAndConditions

            })

            fetchTermsAndConditions();

            alert("The TERMS & CONDITIONS have been successfully updated!")


        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }



    const [listItems, setListItems] = useState([])

    const handleAddListItem = (e) => {
        e.preventDefault();


        if (currentWord) {
            setListItems([...listItems, { contentString: currentWord }]);

            setCurrentWord("");
        }
    };


    const deleteListItem = (keyword) => {

        setListItems((prevKeywords) => prevKeywords.filter((k) => k.contentString !== keyword.contentString));

    };



    const handleEditCurrentContentString = (index, objectIndex, e) => {

        const newTerms = [...termsAndConditions];


        newTerms[index].content[objectIndex].contentString = e.target.value;
        setTermsAndConditions(newTerms);



    }


    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }


    const [seeTerms, setSeeTerms] = useState(false)



    const handleSeeTerms = () => {


        setSeeTerms(!seeTerms)
    }




    function limitString(inputString, limit = 25) {
        // Check if the input string is longer than the specified limit
        if (inputString.length > limit) {
            // Return the truncated string with ellipsis
            return inputString.substring(0, limit) + '...';
        } else {
            // Return the original string if it's within the limit
            return inputString;
        }
    }







    return (

        <>
            {seeTerms ? (

                <div className={classes.hoursWrapper}>


                    <h2 onClick={() => { handleSeeTerms() }}>TERMS & CONDITIONS</h2>

                    <button className={classes.uniButton} onClick={() => { setShowFormPreview(true) }}>See Preview</button>


                    <div className={classes.keywordDiv}>

                        <h2>ADD ITEM</h2>



                        <div className={classes.typeSelection}>


                            <label>
                                Select Type:
                                <select className={classes.titleInput3} value={currentType} onChange={handleTypeChange}>
                                    <option value="">Select an option</option> {/* Non-option */}
                                    <option value="Title">Title</option>
                                    <option value="SubTitle">Sub Title</option>
                                    <option value="Paragraph">Paragraph</option>
                                    <option value="NumberedList">Numbered List</option>

                                    <option value="List">List</option>
                                </select>
                            </label>
                        </div>



                        {
                            currentType === "List" || currentType === "NumberedList" ?


                                (

                                    <div className={classes.contentInputs}>




                                        <div className={classes.keywordDiv}>
                                            <textarea
                                                className={classes.titleInput4}
                                                type="text"
                                                placeholder="Keyword..."
                                                name="keyword"
                                                value={currentWord || ""}
                                                onChange={handleWord}
                                            />
                                            <button type="button" className={classes.uniButton} onClick={handleAddListItem}>
                                                Add List Item
                                            </button>
                                        </div>
                                        <div className={classes.keywords2}>
                                            {listItems.map((keyword, index) => (
                                                <div key={index} className={classes.keyword} >
                                                    <AiOutlineClose onClick={() => {
                                                        deleteListItem(keyword)
                                                    }} className={classes.removeIcon} />
                                                    <p>{keyword.contentString}</p>

                                                </div>
                                            ))}
                                        </div>





                                    </div>

                                ) : (
                                    <div className={classes.contentInputs}>
                                        <textarea
                                            className={classes.titleInput4}
                                            type="text"
                                            placeholder="Keyword..."
                                            name="keyword"
                                            value={currentWord || ""}
                                            onChange={handleWord}
                                        />
                                    </div>
                                )
                        }

                        <div className={classes.plusButtonDiv}>
                            <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />

                        </div>





                    </div>




                    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => { setOpen("") }}>
                        <Droppable droppableId="menuItems">
                            {(provided) => (
                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                    {termsAndConditions?.map((item, index) => (


                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={classes.keyword}
                                                >

                                                    <AiOutlineClose onClick={() => {
                                                        deleteItem(index)
                                                    }} className={classes.removeIcon} />
                                                    <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}><span>{item.elementType}</span>  {item.content[0].contentString !== "" && item.content[0].contentString !== undefined && `: ${limitString(item.content[0].contentString)}`}</p>

                                                    <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>


                                                        {item.content.map((object, objectIndex) => (


                                                            <textarea
                                                                className={classes.titleInput4}
                                                                type="text"
                                                                placeholder="Panel Title..."
                                                                value={object.contentString || ""}
                                                                onChange={(e) => handleEditCurrentContentString(index, objectIndex, e)}
                                                            />

                                                        ))}


                                                    </div>


                                                </div>
                                            )}



                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <button className={classes.formButton2} onClick={handleUpdateTerms}>Update Terms & Conditions</button>

                    <Modal
                        isOpen={showFormPreview}
                        onRequestClose={() => setShowFormPreview(false)}
                        contentLabel="Preview Terms Modal"
                        className="modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999999999999999999999999999999999999",
                            },
                            content: {
                                width: '70%',
                                height: 'auto',
                                maxHeight: '80vh',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#fff",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >
                        <div className={classes.menuContainer}>
                            <AiOutlineClose onClick={() => {
                                setShowFormPreview(false)
                            }} className={classes.removeIcon} />





                            <div className={classes.wrapper}>





                                {termsAndConditions?.map((object, index) => (

                                    <>




                                        {
                                            object.elementType === "Title" && (

                                                <h2>{object.content[0].contentString}</h2>

                                            )


                                        }
                                        {
                                            object.elementType === "SubTitle" && (

                                                <h3>{object.content[0].contentString}</h3>

                                            )

                                        }
                                        {
                                            object.elementType === "Paragraph" && (
                                                <p>{object.content[0].contentString}</p>


                                            )

                                        }
                                        {
                                            object.elementType === "NumberedList" && (

                                                <> {object.content.map((contentItem, index) => (

                                                    <h4>{index}. {contentItem.contentString}</h4>


                                                ))

                                                }
                                                </>

                                            )

                                        }


                                        {object.elementType === "List" && (

                                            <ul> {object.content.map((contentItem, index) => (

                                                <li>{contentItem.contentString}</li>


                                            ))

                                            }
                                            </ul>

                                        )}



                                    </>



                                ))}





                            </div>



                        </div>
                    </Modal>


                </div>
            ) : (


                <div className={classes.hoursWrapper}>

                    <h2 onClick={() => { handleSeeTerms() }}>TERMS & CONDITIONS</h2>



                </div>
            )


            }







        </>



    )
}

export default AdminTerms