import React, {useEffect, useRef, useState} from 'react'
import classes from './contact.module.css'

import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import { request } from '../../util/fetchAPI'





const Contact = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  
const reduxData = useSelector((state) => state.website);

  
  const descRef = useRef([]);

  const [allAgents, setAllAgents] = useState([])


  useEffect(() => {
    const appearOptions = {
      threshold: 0.2,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });



    return () => {
      appearOnScroll.disconnect();
    };
  }, [allAgents]);



  
  const navigate = useNavigate()



  const handleNavigate = (id) => {
    //navigate to properties
     navigate(`/userDetail/${id}`)
}


  useEffect(() => {
    const fetchAllAgents = async() => {
      const data = await request(`/displayUsers/getAll`, 'GET')
      setAllAgents(data)
    }
    fetchAllAgents()
  }, [])




  return (
    <div className={classes.container}>

      
      <div className={classes.wrapper}  >
      {allAgents.map((user, index) => (
  <div onClick={() => handleNavigate(user._id)} key={user._id} className={classes.recs} ref={(el) => (descRef.current[index] = el)}>
    
      <div className={classes.top}>
        <img src={`https://katteochapi.onrender.com/images/${user.profileImg}`} alt="" />
      </div>
      <div className={classes.bottom}>
        <h5>{user.username}</h5>
        <p>
         
          {user.role}
        </p>
      </div>
    
  </div>
))}
      </div>
    </div>
  )
}

export default Contact