import React from 'react'
import classes from './rentOrSell.module.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'


const RentOrSell = () => {


  const reduxData = useSelector((state) => state.website);

  const navigate = useNavigate()

  const handleSearch = () => {
    navigate(`/properties`);
  };

  const handleContact = () => {
    navigate(`/contact`);
  };

  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };


  return (
    <div className={classes.container}>
       <h2>{reduxData.data.propertiesBannerTitle}</h2>
       <p>{reduxData.data.propertiesBannerDesc}</p>
       {reduxData.data.propertiesBannerButtonType === "Properties" && (
          <button onClick={handleSearch} className={classes.buttonKL} >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.propertiesBannerButtonType === "OurTeam" && (
          <button onClick={handleContact} className={classes.buttonKL} >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.propertiesBannerButtonType === "About" && (

          <button onClick={handleAbout} className={classes.buttonKL} >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.propertiesBannerButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className={classes.buttonKL} >
            RENT
          </button>

        )

        }

        {reduxData.data.propertiesBannerButtonType === "WhySell" && (
          <button onClick={handleWhySell} className={classes.buttonKL} >
            SELL
          </button>

        )


        }

        {reduxData.data.propertiesBannerButtonType === "Valuation" && (
          <button onClick={handleValuation} className={classes.buttonKL} >
            GET A VALUATION
          </button>

        )


        }
    </div>
  )
}

export default RentOrSell