import React, {useEffect, useRef} from 'react'
import classes from './contactHero.module.css'
import { useDispatch, useSelector } from 'react-redux'

const ContactHero = () => {

  
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const reduxData = useSelector((state) => state.website);




  return (
    <>
    <div className={classes.containerOne}>
      <div className={classes.wrapper}>
        <h2>{reduxData.data.contactMainBottomTitle}</h2>
        
      </div>
    </div>
    <div className={classes.containerTwo}>
    <div className={classes.wrapperTwo} >
     
      <p className={classes.address}>{reduxData.data.addressLine}</p>
      <p className={classes.cont}>{reduxData.data.contactNumber} || {reduxData.data.contactEmail}</p>
<div className={classes.helloImage}>
      <img   src={`https://katteochapi.onrender.com/images/${reduxData.data.contactMainImg}`} alt=""/>
      </div>
    </div>
  </div>
  </>
  )
}

export default ContactHero