import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import classes from './properties.module.css';
import { request } from '../../util/fetchAPI';



const Properties = (properties) => {
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [preloadImages, setPreloadImages] = useState([])
  const [evalBool, setEvalBool] = useState(false)

  
 
  const [state, setState] = useState({
    propType: '',
    contType: '',
    search: '',
    beds: '',
    bathrooms: '',
    garden: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  


  // fetch all properties


  useEffect(() => {

    
    /*const fetchAllProperties = async () => {
      const data = await request(`/property/getAll`, 'GET');
      setAllProperties(data);

      data.map((property) => {
        preloadImage("https://katteochapi.onrender.com/images/" + property.img)
        return "ok"
     
      })
    };

    fetchAllProperties();*/

    

  }, []);


  useEffect(() => {


    setAllProperties(properties.properties)

  }, [properties])

  // parsing query params

  const updateURLParams = () => {
    const queryStr = new URLSearchParams(state).toString();
    navigate(`/properties?${queryStr}`);
  };


 

    
    

 


  const handleState = (e) => {


    const { name, type, value, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setState((prev) => {
      return { ...prev, [name]: name === "beds" || name === "bathrooms" ? parseInt(newValue, 10) : newValue };
    });




  };


  
  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;

  
    preloadImages.push(img.src)
    

    
  };














  const handleSearch = useCallback((param) => {

    setFilteredProperties([]);

  


    const filteredProperties2 = allProperties.filter((property) => {
      return (
        (!param.propType || property.propType === param.propType) &&
        (!param.contType || property.contType === param.contType) &&
        (!param.beds || property.beds === param.beds) &&
        (!param.bathrooms || property.bathrooms === param.bathrooms) &&
        (!param.garden || property.garden === param.garden)




      );

    });

    const searchParam = param.search;




    const f3 = filteredProperties2.filter((property) =>
      Object.values(property).some((value) =>
        String(value).toLowerCase().includes(searchParam.toLowerCase())
      )
    )


    setFilteredProperties(f3);
   


  }, [allProperties]);




 const handleOnLoad = () => {
  setTimeout(() => {

    setFinishedLoading(true)
    setEvalBool(true)
    
    
  }, [500]);

  
 }







  useEffect(() => {

    const propType = query.get('propType');
    const contType = query.get('contType');
    const search = query.get('search')
    const beds = query.get('beds')
    const bathrooms = query.get('bathrooms')
    const garden = query.get('garden')





    setState({
      propType: propType || '',
      contType: contType || '',
      search: search || '',
      beds: beds || '',
      bathrooms: bathrooms || '',
      garden: garden || false
    });




  }, [location, allProperties]);


  useEffect(() => {

    handleSearch({
      propType: state.propType,
      contType: state.contType,
      search: state.search || '',
      beds: state.beds || "",
      bathrooms: state.bathrooms || "",
      garden: state.garden || false
    })

  }, [state]);





  const handleInputChange = (e) => {
    setState({
      ...state,
      search: e.target.value
    })

  }


























  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.searchBar}>
          <AiOutlineSearch /> <input type="text" placeholder="Search..." value={state.search} className={classes.search} onChange={e =>
            handleInputChange(e)

          } />
        </div>
        <div className={classes.options}>
          <label>
            Property Type:
            <select name="propType" onChange={handleState}>
              <option value="">Any</option>
              <option value="commercial">Commercial</option>
              <option value="private">Private</option>
            </select>
          </label>
          <label>
            Contract Type:
            <select name="contType" onChange={handleState}>
              <option value="">Any</option>
              <option value="let">Let</option>
              <option value="sale">Sale</option>
              <option value="to let">To Let</option>
            </select>
          </label>
          <label>
            No. of beds:
            <select name="beds" onChange={handleState}>
              <option value="">Any number</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </label>
          <label>
            No. of bathrooms:
            <select name="bathrooms" onChange={handleState}>
              <option value="">Any number</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </label>
          <label className={classes.gardenLabel}>
            Garden?
            <input
              type="checkbox"
              name="garden"
              onChange={handleState}
            />
          </label>

        </div>
      </div>
      <div className={classes.bottomWrapper}>



  

        {filteredProperties.length <= 0 && evalBool && <h2>No matches for your search.</h2> }
        {filteredProperties.length > 0 ? (
          <div className={classes.properties}>

            {!finishedLoading && <p className={classes.loader}>Loading...</p> }
            {filteredProperties.map((property, index) => (
              <Link key={property._id} className={classes.property} style={finishedLoading?  {display: "block"} : {display: "none"}} to={`/propertyDetail/${property._id}`}>
                <div className={classes.imgContainer}>
                  <img src={"https://katteochapi.onrender.com/images/" + property.img} onLoad={handleOnLoad} alt="" />
                </div>

                <div className={classes.details}>



                    <h3 className={classes.propName}>{property.title}</h3>
                    {

property.contType === "sold" && property.contType !== undefined ?

  (<span style={{ color: "red", fontWeight: "600" }}>
    {property.contType.toUpperCase()}

  </span>
  ) :

  property.contType === "let" ?

    (

      <span style={{ color: "red", fontWeight: "600" }}>
        {property.contType.toUpperCase()}

      </span>


    ) :

    (


      <span style={{ color: "green", fontWeight: "600" }}>
        {property.contType.toUpperCase()}

      </span>
    )}


                
                </div>
              </Link>
            ))}
          </div>
        ) : (

          <div className={classes.properties}>



          </div>

        )}





      </div>
    </div>
  );
};

export default Properties;
