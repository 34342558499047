
import classes from './propertyDetail.module.css'

import emailjs from '@emailjs/browser'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { request } from '../../util/fetchAPI'
import { FaBed, FaSquareFull, FaBath } from 'react-icons/fa'
import { useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal';
import GoogleMapWithMarker from '../map/Map'
import PropCarousel from '../propCarousel/PropCarousel'
import Carousel from '../slider/Slider'




const PropertyDetail = () => {

  const [styles, setStyles] = useState({
    opacity: "0.2"
  });

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const { token, user } = useSelector((state) => state.auth)
  const [propertyDetail, setPropertyDetail] = useState({})
  const [showForm, setShowForm] = useState(false)

  const [desc, setDesc] = useState("")
  const [success, setSuccess] = useState(false)
  const { id } = useParams()
  const formRef = useRef()
  const navigate = useNavigate()

  const [editState, setEditState] = useState({});

  const isLoading = Object.keys(propertyDetail).length === 0;

  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [testPhotos, setTestPhotos] = useState([]);

  const [slides, setSlides] = useState([]);






  const serviceId = process.env.REACT_APP_SERVICE_ID
  const templateId = process.env.REACT_APP_TEMPLATE_ID
  const publicKey = process.env.REACT_APP_PUBLIC_KEY






  const [location, setLocation] = useState("")


  useEffect(() => {
    let newPhoto = {};
    let newPhotos = [];
    for (let i = 0; i < testPhotos.length; i++) {
      newPhoto = {
        image: testPhotos[i]
      }

      newPhotos.push(newPhoto);


    }

    setSlides([...newPhotos])

  }, [testPhotos]);









  /*const slides = [
    {
      
      image: PF + '1.webp',
    },
    {
      
      image: PF + '4.webp',
    },
    {
     
      image: PF + '5.webp',
    },
    {
      
      image: PF + 'group.jpg',
    },
   
  ]; */


  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };













  useEffect(() => {

    setStyles({
      opacity: "1"
    });
    const fetchDetails = async () => {
      try {
        const data = await request(`/property/find/${id}`, "GET")

        preloadImage(`https://katteochapi.onrender.com/images/${data.energyImg}`)

        setPropertyDetail(data)
        setEditState(data)
        setLocation(data.area + " UK")

        setTestPhotos([
          data.img,
          ...data.photoArray,
        ]);

        data.photoArray.map((img) => {
          preloadImage("https://katteochapi.onrender.com/images/" + img)
          return "ok"

        })







        console.log(data)
        console.log(location)

      } catch (error) {
        console.error(error)
      }
    }
    fetchDetails()
  }, [location, id])






  const handleCloseForm = () => {

    setShowForm(false)
    setDesc('')
  }










  const handleContactOwner = async (e) => {
    e.preventDefault()

    emailjs.sendForm(serviceId, templateId, formRef.current, publicKey)
      .then((result) => {
        handleCloseForm()
        setShowModal2(false)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)

        }, 1100)
      }, (error) => {
        console.log(error.text);
      });
  }

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

































  const [inputValue, setInputValue] = useState("admin@kateeoh.com");
  const isInputDisabled = true; // Set this to true or false based on your condition

  const handleChange = (e) => {
    if (!isInputDisabled) {
      setInputValue(e.target.value);
    }
  };

  const handleProperties = () => {

    //navigate to properties
    navigate(`/properties?propType=&contType=&search=`)

  }








  return (
    <div className={classes.container}>


      {isLoading ? (
        <p className={classes.loader}>Loading...</p> // Placeholder for when data is being fetched
      ) : (<>

        <div className={classes.wrapper} style={styles}  >



          <Carousel autoPlay={true} useRightLeftTriangles={true} slides={slides} />


          <div className={classes.right}>




            <h3 className={classes.title}>
              {propertyDetail?.title && propertyDetail?.title + ", "}
              {propertyDetail?.area && propertyDetail?.area + ", "}
              {propertyDetail?.postcode && propertyDetail?.postcode}




            </h3>
            <div className={classes.details}>
              <div className={classes.typeAndContinent}>



                {propertyDetail?.keywordArray && propertyDetail.keywordArray.slice(0, 5).map((word, index) => (
                  <div key={index}>
                    <span>{word}</span>
                  </div>
                ))}

              </div>
              <div className={classes.priceAndOwner}>
                <span className={classes.price}>




                  {propertyDetail?.contType === "sold" && propertyDetail?.contType !== undefined ?

                      (<span style={{ color: "red", fontWeight: "600" }}>
                        {propertyDetail?.contType.toUpperCase()}

                      </span>) :

                      propertyDetail?.contType === "let" ?

                        (

                          <span style={{ color: "red", fontWeight: "600" }}>
                            {propertyDetail?.contType.toUpperCase()}

                          </span>


                        ) :

                        (


                          <span style={{ color: "green", fontWeight: "600" }}>
                            {propertyDetail?.contType.toUpperCase()}

                          </span>
                        )}


                  {(propertyDetail?.contType === "to let" || propertyDetail?.contType === "sale") && (<span className={classes.space}> {`${propertyDetail?.price}`}</span>)}
                  </span>
                
              </div>
              <div className={classes.moreDetails}>

                {propertyDetail?.propType === "private" && (<>
                  <span>{propertyDetail?.beds} <FaBed className={classes.icon} /></span>
                  <span>{propertyDetail?.bathrooms} <FaBath className={classes.icon} /></span>
                </>)

                }
                <span>{propertyDetail?.sqmeters} sqmetres <FaSquareFull className={classes.icon} /></span>

              </div>
              <div className={classes.moreDetails}>
                <span>Council Tax: <span className={classes.bold}>{propertyDetail?.councilTaxBand} </span></span>
                {propertyDetail?.garden === true &&
                  <span> Garden</span>
                }


              </div>

              <div className={classes.moreDetails}>


                <span> Property Type: <span className={classes.bold}> {propertyDetail?.buildType.toUpperCase()} </span></span>
              </div>

            </div>

            <div className={classes.photosDiv}>

              <button onClick={() => setShowModal2(true)} className={classes.contactOwner}>
                Contact Agent
              </button>

              <button onClick={handleProperties} className={classes.contactOwner} id={classes.contactOwnerId}>
                Back to Properties
              </button>

            </div>

            {showModal2 && (
              <Modal
                isOpen={showModal2}
                onRequestClose={() => setShowModal2(false)}
                contentLabel="Delete User Modal"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '999'
                  },
                  content: {
                    alignSelf: 'center',
                    margin: '10px',
                    maxWidth: '90%',
                    width: 'auto',
                    height: 'auto',
                    paddingTop: "45px",
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#222',
                    backgroundColor: '#faf9f6',
                    border: '0',
                    borderRadius: '20px',
                
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
                    gap: "2"
                  },
                }}



              >
                <AiOutlineClose onClick={() => {
                  setShowModal2(false);
                }} className={classes.removeIcon} />
                <div onClick={handleCloseForm} className={classes.lastMinute}>

                  <div className={classes.formDiv} onClick={(e) => e.stopPropagation()}>

                    <h2>Send Email Kateeoh</h2>
                    <form onSubmit={handleContactOwner} ref={formRef}>

                      <input className={classes.speshInput}    type="text" placeholder='My email' name="from_email" />
                      <input className={classes.speshInput}   type="text" placeholder='My name' name="from_username" />
                      <textarea className={classes.descrip} value={desc} type="textarea" placeholder='Message...' name="message" onChange={(e) => setDesc(e.target.value)} />
                      <input value={inputValue} style={{ display: "none" }} type="email" placeholder='Owner email' name="to_email" onChange={handleChange} />
                      <input value={propertyDetail?.title} style={{ display: "none" }} type="text" name="property" onChange={handleChange} />

                      <button className={classes.emailBtn}>Send</button>
                    </form>

                  </div>
                </div>

              </Modal>
            )}

            <div className={classes.photosDiv}>

              {propertyDetail.energyImg !== "" && (
                <div className={classes.photosDivButton} onClick={() => setShowModal3(true)}>
                  <img src={`https://katteochapi.onrender.com/images/${propertyDetail?.energyImg}`} alt="mini energy" />

                </div>
              )}



              {propertyDetail.floorPlanImgs.length > 0 && (
                <div className={classes.photosDivButton} onClick={() => setShowModal4(true)}>
                  <img src={`https://katteochapi.onrender.com/images/${propertyDetail?.floorPlanImgs[0]}`} alt="floorplan" />


                </div>
              )}



            </div>

            {showModal3 && (
              <Modal
                isOpen={showModal3}
                onRequestClose={() => setShowModal3(false)}
                contentLabel="Delete User Modal"
                className="modal"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: "99999999999"
                  },
                  content: {
                    alignSelf: 'center',
                    margin: '10px',
                    width: 'auto',

                    height: '500px',

                    paddingTop: "1vh",
                    position: 'fixed',
                    zIndex: "9999999999999",
                    overflowX: "scroll",
                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#222',
                    backgroundColor: '#faf9f6',
                    border: '0',
                    borderRadius: '20px',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',


                  },
                }}




              >
                <AiOutlineClose onClick={() => setShowModal3(false)} className={classes.removeIcon} />
                <div className={classes.energyModal}>
                  <h3>Energy Ratings</h3>
                  <img alt="energy" src={`https://katteochapi.onrender.com/images/${propertyDetail?.energyImg}`} />
                </div>
              </Modal>
            )}

            {showModal4 && (
              <Modal
                isOpen={showModal4}
                onRequestClose={() => setShowModal4(false)}
                contentLabel="Delete User Modal"
                className="modal"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: "99999999999"
                  },
                  content: {
                    alignSelf: 'center',
                    margin: '10px',

                    width: 'auto',
                    height: '500px',
                    paddingTop: "1vh",
                    position: 'fixed',
                    zIndex: "9999999999999",
                    overflowX: "scroll",

                    top: '55%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#222',
                    backgroundColor: '#faf9f6',
                    border: '0',
                    borderRadius: '20px',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
                    gap: "2"
                  },
                }}



              >
                <AiOutlineClose onClick={() => setShowModal4(false)} className={classes.removeIcon} />
                <div className={classes.energyModal}>
                  <h3>Floor Plan</h3>
                  <PropCarousel photos={propertyDetail?.floorPlanImgs} className={classes.floorPlanImgs} />
                </div>
              </Modal>
            )}
          </div>
        </div>
        <p className={classes.desc} >
          {propertyDetail?.desc && <span>{propertyDetail.desc}</span>}
        </p>
        <div className={classes.map} >
          <GoogleMapWithMarker address={location} />
        </div>
        <div className={classes.stationBox} >
          <h3>Closest Stations</h3>
          <div className={classes.stationItems}>
            {propertyDetail?.closestStations && propertyDetail.closestStations.map((word, index) => (
              <div className={classes.stationItem} key={index}> <img alt="logo" src={PF + "under.png"} /> <span key={index}> {word}</span></div>
            ))}

          </div>
        </div>

      </>)}







      {success === true ? (

        <div className={`${classes.success} ${classes.show}`}>
          You've successfully contacted the estate agent!
        </div>
      ) : (
        <div className={classes.success}>
          You've successfully contacted the estate agent!
        </div>


      )}







    </div>
  )
}

export default PropertyDetail