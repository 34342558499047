import React from 'react'
import classes from "./reviewBanner.module.css"
import {  AiFillStar } from 'react-icons/ai';
import {  useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const ReviewBanner = () => {

  const navigate = useNavigate()
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };


  

  const reduxData = useSelector((state) => state.website);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  preloadImage(PF + "reviews.jpg")
  
  return (
    <div className={classes.container}>
      <div className={classes.abImg}>
        <img src={`https://katteochapi.onrender.com/images/${reduxData.data.reviewHeroImg}`} alt="background" />
      </div>
        <div className={classes.absolute}>
      <div className={classes.left}>
      <h2>{reduxData.data.reviewHeroTitle}</h2>

      

  
   
          {reduxData.data.reviewHeroButtonType === "Properties" && (
            <button onClick={handleSearch} className={classes.valButton} >
              OUR PORTFOLIO
            </button>
          )


          }


          {reduxData.data.reviewHeroButtonType === "OurTeam" && (
            <button onClick={handleContact} className={classes.valButton} >
              MEET THE TEAM
            </button>
          )


          }

          {reduxData.data.reviewHeroButtonType === "About" && (

            <button onClick={handleAbout} className={classes.valButton} >
              OUR MISSION
            </button>
          )


          }

          {reduxData.data.reviewHeroButtonType === "WhyLet" && (
            <button onClick={handleWhyLet} className={classes.valButton} >
              RENT
            </button>

          )

          }

          {reduxData.data.reviewHeroButtonType === "WhySell" && (
            <button onClick={handleWhySell} className={classes.valButton} >
              SELL
            </button>

          )


          }

          {reduxData.data.reviewHeroButtonType === "Valuation" && (
            <button onClick={handleValuation} className={classes.valButton} >
              GET A VALUATION
            </button>

          )


          }
     
      </div>
    </div>
    </div>
  )
}

export default ReviewBanner