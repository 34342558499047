import React, { useEffect, useState } from 'react'
import classes from "./dashboardEdit.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'

const DashboardEdit = ({ property, closeModal, fetchFunction, preloaderFunction }) => {

    const { token, user } = useSelector((state) => state.auth)



    const { id } = useParams()

    const navigate = useNavigate()
    const [currentWord, setCurrentWord] = useState("");
    const [currentWord2, setCurrentWord2] = useState("");
    const [newOwner, setNewOwner] = useState("")
    const [newPhotos, setNewPhotos] = useState([]);
    const [newPhotos2, setNewPhotos2] = useState([]);
    const [state, setState] = useState({

        area: "",
        bathrooms: 0,
        title: "",
        price: "",
       
        beds: 0,
        buildType: "",
        closestStations: [],
        contType: "l",
        councilTaxBand: "",
        createdAt
            :
            "2023-06-02T11:18:36.142Z",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",
        },
        desc
            :
            "",
        energyImg
            :
            "",
        featured
            :
            false,
        floorPlanImg
            :
            "",
        floorPlanImgs
            :
            [],
        garden
            :
            false,
        img
            :
            "",
        keywordArray
            :
            [],
        photoArray
            :
            [],
        postcode
            :
            "",
        price
            :
            "",
        propType
            :
            "",
        sqmeters
            :
            0,
        title
            :
            "",
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",

    })
    const [allAgents, setAllAgents] = useState([]);
    const [photos, setPhotos] = useState(state?.floorPlanImgs);
    const [photos2, setPhotos2] = useState(state?.photoArray);

    const [selectedProp, setSelectedProp] = useState(state?.propType);
    const [selectedCont, setSelectedCont] = useState(state?.contType);
    const [selectedBuild, setSelectedBuild] = useState(state?.buildType);
    const [currentOwner, setCurrentOwner] = useState(state?.currentOwner)
    const [userDetail, setUserDetail] = useState({})
    const [photo, setPhoto] = useState(state?.img)
    const [photo2, setPhoto2] = useState(state?.energyImg)
    const [keywords, setKeywords] = useState([]);
    const [keywords2, setKeywords2] = useState([]);





    useEffect(() => {
        const fetchAllAgents = async () => {
            const data = await request(`/property/agents`, 'GET')
            setAllAgents(data)
        }
        fetchAllAgents()

    }, [])


    const fetchDetails = async () => {
        try {
            const data = await request(`/property/agent/${newOwner}`, 'GET');
            setUserDetail(data);

        } catch (error) {
            console.error(error);
        }
    };


    const handleOption = (e) => {

        const selectedDataId = e.target.value;
        setNewOwner(selectedDataId)
    }



    useEffect(() => {

        if (newOwner !== "") {

            fetchDetails();


        }


    }, [newOwner]);





    useEffect(() => {
        console.log(typeof photo2)

        console.log(typeof photo2 === "string")


    }, [photo2])



    useEffect(() => {
        console.log(typeof photo)
        console.log(typeof photo === "string")
        console.log(photo)

    }, [photo])








    useEffect(() => {
        setState({
            ...property,
        });
        setKeywords(property?.keywordArray);
        setKeywords2(property?.closestStations);
        setPhotos(property?.floorPlanImgs);
        setPhotos2(property?.photoArray);
        setPhoto(property?.img)
        setPhoto2(property?.energyImg)
        setSelectedProp(property?.propType);
        setSelectedCont(property?.contType);
        setSelectedBuild(property?.buildType);
        setCurrentOwner(property.currentOwner);

        console.log(property);


    }, [property]);



    useEffect(() => {

        console.log(state);



    }, [state])






    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })


    }

    const handleUploadPhotoEdit = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);


    };

    const handleUploadPhoto2Edit = (e) => {
        const files = Array.from(e.target.files);

        setPhotos2([...photos2, ...files]);


    };
























    const handleListProperty = async (e) => {
        e.preventDefault();

        preloaderFunction(true);


        try {

        let dateStampedPhotos = [];
        let dateStampedPhotos2 = [];


        let initialPhotos = property.floorPlanImgs;
        let initialPhotos2 = property.photoArray;

        // Current array of photos (new photos might have been added)


        // Function to determine if a photo is new (added to the array)
        function isPhotoEdited(photo, initialPhotos) {
            return !initialPhotos.includes(photo);
        }

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos = [...photos]; // Create a copy of photos2
        let editOldPhotos = [];
        let editOldPhotosIndices = [];
        let remainingOldPhotos = [];
        let remainingOldPhotosIndices = [];

        oldPhotos.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos)) {
                editOldPhotos.push(photo);
                editOldPhotosIndices.push(index);
            } else {
                remainingOldPhotos.push(photo);
                remainingOldPhotosIndices.push(index);
            }
        });

        let processedPhotos = [];

        if (editOldPhotos.length > 0) {
            const uploadPromises = editOldPhotos.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos) {
                editOldPhotos[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos = new Array(oldPhotos.length);

        // Place edited photos back into their original positions
        editOldPhotos.forEach((photo, i) => {
            combinedPhotos[editOldPhotosIndices[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos.forEach((photo, i) => {
            combinedPhotos[remainingOldPhotosIndices[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos = combinedPhotos;

        console.log(photos); // Output the final array of photos



        let oldPhotos2 = [...photos2]; // Create a copy of photos2
        let editOldPhotos2 = [];
        let editOldPhotosIndices2 = [];
        let remainingOldPhotos2 = [];
        let remainingOldPhotosIndices2 = [];

        oldPhotos2.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos2)) {
                editOldPhotos2.push(photo);
                editOldPhotosIndices2.push(index);
            } else {
                remainingOldPhotos2.push(photo);
                remainingOldPhotosIndices2.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPhotos2.length > 0) {
            const uploadPromises = editOldPhotos2.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos2.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPhotos2[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos2 = new Array(oldPhotos2.length);

        // Place edited photos back into their original positions
        editOldPhotos2.forEach((photo, i) => {
            combinedPhotos2[editOldPhotosIndices2[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos2.forEach((photo, i) => {
            combinedPhotos2[remainingOldPhotosIndices2[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos2 = combinedPhotos2;





        let filename = null;
        let filename2 = null;


        if (photo !== property.img) {

            if (photo === ""){

                filename = ""



            }  else {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
            }
        }
        else {
            filename = property.img
        }


        if (photo2 !== property.energyImg) {   


            if (photo2 === ""){

                filename2 = ""



            } else {
                const formData = new FormData()




            filename2 = crypto.randomUUID() + photo2.name
            formData.append("filename", filename2)
            formData.append("image", photo2)

            await request('/upload/image', "POST", {}, formData, true)

            }

            
        } 
        else {
            filename2 = property.energyImg
        }



       
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;



            if (userDetail.username !== undefined) {




                data = await request(`/property/${property?._id}`, 'PUT', options, {
                    ...state,
                    keywordArray: keywords,
                    photoArray: finalPhotos2,
                    img: filename,
                    energyImg: filename2,
                    floorPlanImgs: finalPhotos,
                    currentOwner: userDetail
                })

            }

            else {

                data = await request(`/property/${property?._id}`, 'PUT', options, {
                    ...state,
                    keywordArray: keywords,
                    photoArray: finalPhotos2,
                    img: filename,
                    energyImg: filename2,
                    floorPlanImgs: finalPhotos,
                })

            }




            setPhoto("");
            setPhoto2("");
            setUserDetail({})
            fetchFunction();
            setState({})

            

            alert("Your property has been successfully updated!")
            closeModal();
            preloaderFunction(false);

        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");
            preloaderFunction(false);

        }

    }


    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }

    const handleWord2 = (e) => {
        setCurrentWord2(e.target.value)
    }


    const handleAddKeyword = (e) => {
        e.preventDefault();
        if (currentWord) {
            setKeywords([...keywords, currentWord]);
            setState({ ...state, keywordArray: [...keywords, currentWord] })
            setCurrentWord("");
        }


    };

    const handleAddKeyword2 = (e) => {
        e.preventDefault();
        if (currentWord2) {
            setKeywords2([...keywords2, currentWord2]);
            setState({ ...state, closestStations: [...keywords2, currentWord2] })
            setCurrentWord2("");
        }


    };


    const deleteKeyword = (keyword) => {
        setKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
    };

    const deleteKeyword2 = (keyword) => {
        setKeywords2((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
    };




    const deletePhoto = (photo) => {

        setNewPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };


    const deletePhoto2 = (photo) => {

        setNewPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
        setPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos(updatedItems);
    };


    const onDragEnd2 = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos2);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos2(updatedItems);
    };










    return (

        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
            <h2>Edit Property</h2>
            <form onSubmit={handleListProperty}>
                <div className={classes.inputs}>
                    <label>
                        Property Name:
                        <input type="text" value={state.title} name='title' onChange={handleState} />
                    </label>
                    <label>
                        Council:
                        <input type="text" value={state.councilTaxBand} name='councilTaxBand' onChange={handleState} />
                    </label>
                    <label>
                        Area:
                        <input type="text" value={state.area} name='area' onChange={handleState} />

                    </label>
                    <label>
                        Postcode:
                        <input type="text" value={state.postcode} name='postcode' onChange={handleState} />
                    </label>
                    <label>
                        Price (please provide "£"):
                        <input type="text" value={state.price} name='price' onChange={handleState} />

                    </label>
                    <label>
                        Square Metres:
                        <input type="number" value={state.sqmeters} name='sqmeters' onChange={handleState} />
                    </label>
                    <label>

                        No. of Beds:
                        <input type="number" value={state.beds} step={1} name='beds' onChange={handleState} />
                    </label>

                    <label>
                        No. of Bathrooms:
                        <input type="number" value={state.bathrooms} step={1} name='bathrooms' onChange={handleState} />

                    </label>

                </div>



                <h3 className={classes.scripTitle}>Property description:</h3>
                <textarea className={classes.scrip} type="text" value={state.desc} name='desc' onChange={handleState} />



                <h3 className={classes.scripTitle}>Property types:</h3>

                <div className={classes.types}>
                    <div className={classes.type}>
                        <label>Property Type:</label>
                        <div className={classes.radio}>
                            <label>
                                <input
                                    type="radio"
                                    name="propType"
                                    value="commercial"
                                    checked={state.propType === "commercial"}
                                    onChange={handleState}
                                />
                                Commercial
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="propType"
                                    value="private"
                                    checked={state.propType === "private"}
                                    onChange={handleState}
                                />
                                Private
                            </label>

                        </div>

                    </div>
                    <div className={classes.type}>
                        <label>Building Type:</label>
                        <div className={classes.radio}>
                            <label>
                                <input
                                    type="radio"
                                    name="buildType"
                                    value="flat"
                                    checked={state.buildType === "flat"}
                                    onChange={handleState}
                                />
                                Flat
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="buildType"
                                    value="house"
                                    checked={state.buildType === "house"}
                                    onChange={handleState}
                                />
                                House
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="buildType"
                                    value="office"
                                    checked={state.buildType === "office"}
                                    onChange={handleState}
                                />
                                Office
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="buildType"
                                    value="retail"
                                    checked={state.buildType === "retail"}
                                    onChange={handleState}
                                />
                                Retail
                            </label>

                        </div>
                    </div>

                    <div className={classes.type}>
                        <label>Contract Type:</label>
                        <div className={classes.radio}>
                            <label>
                                <input
                                    type="radio"
                                    name="contType"
                                    value="let"
                                    checked={state.contType === "let"}
                                    onChange={handleState}
                                />
                                Let
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="contType"
                                    value="sale"
                                    checked={state.contType === "sale"}
                                    onChange={handleState}
                                />
                                Sale
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="contType"
                                    value="to let"
                                    checked={state.contType === "to let"}
                                    onChange={handleState}
                                />
                                To Let
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="contType"
                                    value="sold"
                                    checked={state.contType === "sold"}
                                    onChange={handleState}
                                />
                                Sold
                            </label>

                        </div>

                    </div>
                    <label className={classes.garden}>
                        Garden?
                        <input
                            type="checkbox"
                            name="garden"
                            checked={state.garden}
                            onChange={handleState}
                        />
                    </label>
                </div>

                <h3 className={classes.scripTitle}>Photos:</h3>
                <div className={classes.types2}>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="photoEditNull">Main Image</label>

                        <input
                            id="photoEditNull"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto(e.target.files[0]) }}
                           
                        />

                        {photo === "" ? (
                            <p>No image present</p>


                        ) : photo !== "" && typeof photo === "string" ?



                            (
                                <img src={`https://katteochapi.onrender.com/images/${photo}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            ) :


                            (
                                <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                              
                            )}
                    </div>



                    <div className={classes.upload}>
                        <label className={classes.btnUpload2} htmlFor="photo2Edit">Energy Rating</label>
                        <div onClick={() => { setPhoto2("") }} type="button" className={classes.btnUpload}>Empty Energy Img</div>
                        <input
                            id="photo2Edit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto2(e.target.files[0]) }}
                        />

                        {photo2 === "" ? (
                            <p>No image present</p>


                        ) : photo2 !== "" && typeof photo2 === "string" ?



                            (
                                <img src={`https://katteochapi.onrender.com/images/${photo2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                            ) :


                            (
                               
                                    <img src={URL.createObjectURL(photo2)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                   
                            )}
                    </div>









                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="photosEdit">Floor Plan Images</label>
                        <input id="photosEdit" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit} />

                        <DragDropContext onDragEnd={onDragEnd} >
                            <Droppable droppableId="panelItems">
                                {(provided) => (
                                    <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                        {photos?.map((photo, index) => (

                                            <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                {(provided) => (

                                                    <div className={classes.keyword} ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps} key={index}>

                                                        <AiOutlineClose onClick={() => {
                                                            deletePhoto(photo)
                                                        }} className={classes.removeIcon} />
                                                        <img
                                                            src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://katteochapi.onrender.com/images/${photo}`}
                                                            style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                            alt={`Uploaded ${index + 1}`}
                                                        />

                                                    </div>
                                                )}



                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </div>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="photos2Edit">Side Images</label>
                        <input id="photos2Edit" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhoto2Edit} />
                        <div className={classes.keywords} >
                            <DragDropContext onDragEnd={onDragEnd2} >
                                <Droppable droppableId="panelItems">
                                    {(provided) => (
                                        <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                            {photos2?.map((photo, index) => (

                                                <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                    {(provided) => (

                                                        <div className={classes.keyword} ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps} key={index}>

                                                            <AiOutlineClose onClick={() => {
                                                                deletePhoto2(photo)
                                                            }} className={classes.removeIcon} />
                                                            <img
                                                                src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://katteochapi.onrender.com/images/${photo}`}
                                                                style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                alt={`Uploaded ${index + 1}`}
                                                            />

                                                        </div>
                                                    )}



                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </div>
                    </div>
                </div>


                <h3 className={classes.scripTitle}>Keywords:</h3>



                <div className={classes.keywordDiv}>
                    <input type="text" name="keyword" value={currentWord || ''} onChange={handleWord} />
                    <button type="button" className={classes.photoDel} onClick={handleAddKeyword}>
                        Add Keyword
                    </button>
                </div>

                <div className={classes.keywords2}>
                    {keywords?.map((keyword) => (
                        <div key={keyword} className={classes.keyword} >
                            <p>{keyword}</p>
                            <button className={classes.photoDel} onClick={() => {
                                deleteKeyword(keyword)

                            }}>Delete</button>
                        </div>
                    ))}
                </div>

                <h3 className={classes.scripTitle}>Closest Stations:</h3>

                <div className={classes.keywordDiv}>
                    <input type="text" name="keyword" value={currentWord2 || ''} onChange={handleWord2} />
                    <button type="button" className={classes.photoDel} onClick={handleAddKeyword2}>
                        Add Keyword
                    </button>

                </div>

                <div className={classes.keywords2}>
                    {keywords2?.map((keyword) => (
                        <div key={keyword} className={classes.keyword} >
                            <p>{keyword}</p>
                            <button className={classes.photoDel} onClick={() => {
                                deleteKeyword2(keyword)

                            }}>Delete</button>
                        </div>
                    ))}
                </div>


               


                <button className={classes.formButton2} type="submit">Update Property</button>
            </form>



        </div>


    )
}

export default DashboardEdit