import React from 'react'
import classes from './contactInfo.module.css'
import { useDispatch, useSelector } from 'react-redux'

const ContactInfo = () => {


  const reduxData = useSelector((state) => state.website);





  return (
    <div className={classes.container}>
      <div className={classes.wrapper} >
        <h2>{reduxData.data.contactMainTitle}</h2>
        <p>{reduxData.data.contactMainDesc}</p>
      </div>
    </div>
  )
}

export default ContactInfo