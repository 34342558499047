import React, { useState } from 'react';
import { GiClawSlashes } from 'react-icons/gi';
import classes from "./propCarousel.module.css"

const PropCarousel = ({ photos }) => {

    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentPhotoIndex(prevIndex => (prevIndex === 0 ? photos.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentPhotoIndex(prevIndex => (prevIndex === photos.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className={classes.container} style={{ position: 'relative',  height: "480px",  width: "100%"}}>
      <img
        src={ `https://katteochapi.onrender.com/images/${ photos[currentPhotoIndex]}`}
        alt={`index${currentPhotoIndex + 1}`}
        style={{ width: '100%', height: "100%"}}
      />
      <button
        onClick={handlePrevClick}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: '30px',
          color: 'black',
          zIndex: 0,
        }}
      >
        &lt;
      </button>
      <button
        onClick={handleNextClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: '30px',
          color: 'black',
          zIndex: 0,
        }}
      >
        &gt;
      </button>
    </div>
  );
};

export default PropCarousel;