import React from 'react'
import classes from "./stampDuty.module.css"
import StampDutyCalculator from '../stampDutyCalculator/StampDutyCalculator'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const StampDuty = () => {

  const reduxData = useSelector((state) => state.website);

  const navigate = useNavigate()


  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };



  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <h4>{reduxData.data.stampDutyMainTitle}</h4>

        <p>{reduxData.data.stampDutyMainDesc}</p>

        <div className={classes.box}>
          <StampDutyCalculator/>

        </div>
        <p>{reduxData.data.valuationDesc}</p>
        {reduxData.data.stampDutyMainButtonType === "Properties" && (
            <button onClick={handleSearch} className={classes.speshButton} >
              OUR PORTFOLIO
            </button>
          )


          }


          {reduxData.data.stampDutyMainButtonType === "OurTeam" && (
            <button onClick={handleContact} className={classes.speshButton} >
              MEET THE TEAM
            </button>
          )


          }

          {reduxData.data.stampDutyMainButtonType === "About" && (

            <button onClick={handleAbout} className={classes.speshButton} >
              OUR MISSION
            </button>
          )


          }

          {reduxData.data.stampDutyMainButtonType === "WhyLet" && (
            <button onClick={handleWhyLet} className={classes.speshButton} >
              RENT
            </button>

          )

          }

          {reduxData.data.stampDutyMainButtonType === "WhySell" && (
            <button onClick={handleWhySell} className={classes.speshButton} >
              SELL
            </button>

          )


          }

          {reduxData.data.stampDutyMainButtonType === "Valuation" && (
            <button onClick={handleValuation} className={classes.speshButton} >
              GET A VALUATION
            </button>

          )


          }

      </div>
    </div>
  )
}

export default StampDuty