import React, { useEffect, useRef } from 'react'
import classes from './whySell.module.css'


import { useDispatch, useSelector } from 'react-redux'


import { useNavigate } from 'react-router-dom';

const WhySell = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const reduxData = useSelector((state) => state.website);



  const descRef = useRef([]);


  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };






  useEffect(() => {
    const appearOptions = {
      threshold: 0.05,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });





    return () => {
      appearOnScroll.disconnect();
    };
  }, []);



  const navigate = useNavigate()



  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <h4>{reduxData.data.whySellPanelsHeaderTitle}</h4 >
        <p>{reduxData.data.whySellPanelsHeaderDesc} </p>
        <div className={classes.box}>
          <div className={classes.row} ref={(el) => (descRef.current[0] = el)}>
            <div className={classes.imgContainer}>
              <img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.whySellPanelsImg1}`} />
            </div>
            <div className={classes.alignRight} >
              <h5>{reduxData.data.whySellPanelsTitle1}</h5>
              <p> {reduxData.data.whySellPanelsDesc1}
              </p>

              {reduxData.data.whySellPanelsButton1 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnSell} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whySellPanelsButton1 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnSell} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton1 === "About" && (

                <button onClick={handleAbout} className={classes.btnSell} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton1 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnSell} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whySellPanelsButton1 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnSell} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whySellPanelsButton1 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnSell} >
                  GET A VALUATION
                </button>

              )
              }
            </div>
          </div>

          <div id={classes.dis} className={classes.row} ref={(el) => (descRef.current[1] = el)}>
            <div className={classes.alignLeft} >

              <h5>{reduxData.data.whySellPanelsTitle2}</h5>
              <p>{reduxData.data.whySellPanelsDesc2}
              </p>

              {reduxData.data.whySellPanelsButton2 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnSell} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whySellPanelsButton2 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnSell} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton2 === "About" && (

                <button onClick={handleAbout} className={classes.btnSell} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton2 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnSell} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whySellPanelsButton2 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnSell} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whySellPanelsButton2 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnSell} >
                  GET A VALUATION
                </button>

              )
              }
            </div>
            <div className={classes.imgContainer}>
              <img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.whyLetPanelsImg2}`} />
            </div>
          </div>
          <div className={classes.row} ref={(el) => (descRef.current[2] = el)}>
            <div className={classes.imgContainer}>
              <img alt="" src={PF + "oldbatch6.jpg"} />
            </div>
            <div className={classes.alignRight} >
              <h5>{reduxData.data.whySellPanelsTitle3}</h5>
              <p >{reduxData.data.whyLetPanelsDesc3}
              </p>

              {reduxData.data.whySellPanelsButton3 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnSell} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whySellPanelsButton3 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnSell} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton3 === "About" && (

                <button onClick={handleAbout} className={classes.btnSell} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whySellPanelsButton3 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnSell} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whySellPanelsButton3 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnSell} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whySellPanelsButton3 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnSell} >
                  GET A VALUATION
                </button>

              )
              }
            </div>
          </div>



        </div>


        <h6>{reduxData.data.whySellPanelsBottomCaption}</h6>
        {reduxData.data.whySellPanelsBottomButtonType === "Properties" && (
          <button onClick={handleSearch} className={classes.btnSell} >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.whySellPanelsBottomButtonType === "OurTeam" && (
          <button onClick={handleContact} className={classes.btnSell} >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.whySellPanelsBottomButtonType === "About" && (

          <button onClick={handleAbout} className={classes.btnSell} >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.whySellPanelsBottomButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className={classes.btnSell} >
            RENT
          </button>

        )

        }

        {reduxData.data.whySellPanelsBottomButtonType === "WhySell" && (
          <button onClick={handleWhySell} className={classes.btnSell} >
            SELL
          </button>

        )


        }

        {reduxData.data.whySellPanelsBottomButtonType === "Valuation" && (
          <button onClick={handleValuation} className={classes.btnSell} >
            GET A VALUATION
          </button>

        )
        }
      </div>
    </div>

  )
}

export default WhySell;