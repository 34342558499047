import './App.css';
import { useState, useEffect, useLayoutEffect } from 'react';
import Footer from './components/footer/Footer';
import Navbar2 from './components/Navbar2/Navbar2.jsx';
import Properties from './components/properties/Properties';
import PropertyDetail from './components/propertyDetail/PropertyDetail';
import Signin from './components/signin/Signin';
import Signup from './components/signup/Signup';
import { Route, Routes } from 'react-router-dom';
import StampDuty from './components/stampDuty/StampDuty';
import Valuation from './components/valuation/Valuation';
import WhySell from './components/whySell/WhySell';
import WhyLet from './components/whyLet/WhyLet';
import Reviews from './components/reviews/Reviews';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Slideshow from './components/slide/Slide';
import WhyKStrip from './components/whyKStrip/WhyKStrip';
import Tiles from './components/tiles/Tiles';
import ReviewStrip from './components/reviewStrip/ReviewStrip';
import TeamStrip from './components/teamStrip/TeamStrip';
import StampBanner from './components/stampBanner/StampBanner';
import ReviewBanner from './components/reviewBanner/ReviewBanner';
import AboutBanner from './components/aboutBanner/AboutBanner';
import ContactBanner from './components/contactBanner/ContactBanner';
import WhyLetInfo from './components/whyLetInfo/WhyLetInfo';

import ContactInfo from './components/contactInfo/ContactInfo';
import ContactHero from './components/contactHero/ContactHero';
import RentOrSell from './components/rentOrSell/RentOrSell';
import AgentDetail from './components/agentDetail/AgentDetail';

import ScrollToTop from './components/scrollToTop/ScrollToTop';
import PrivacyNotice from './components/privacyNotice/PrivacyNotice';
import TsAndCs from './components/tsAndCs/TsAndCs';
import Dashboard from './components/dashboard/Dashboard';
import { useDispatch, useSelector } from 'react-redux'
import { getData } from './redux/websiteSlice'
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


import { request } from './util/fetchAPI';

const PF = process.env.REACT_APP_PUBLIC_FOLDER;


const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

// Preload the image
preloadImage(PF + "background1.webp");
preloadImage(PF + "about.webp");
preloadImage(PF + "kitchen1.webp");
preloadImage(PF + "reviews.jpg")
preloadImage(PF + 'DanPic.webp');
preloadImage(PF + 'stairs2.webp');
preloadImage(PF + 'front.webp');
preloadImage(PF + "scan1.webp");
preloadImage(PF + "beauty.webp");
preloadImage(PF + "beauty2.webp");
preloadImage(PF + "beauty3.webp");
preloadImage(PF + "fullview.webp");
preloadImage(PF + "office.jpg")
preloadImage(PF + "oldbatch4.jpg")
preloadImage(PF + "oldbatch7.jpg")
preloadImage(PF + "oldbatch6.jpg")













function App() {



  const [allProperties, setAllProperties] = useState([]);
  const [blob, setBlob] = useState("")
  const [og, setOg] = useState("")

  const reduxData = useSelector((state) => state.website);



  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      // Set a timeout to simulate a delay (adjust as needed)
      setTimeout(() => {
        setIsLoaded(true);
      }, 400); // 1000 milliseconds = 1 second
    };

    // Attach the handleLoad function to the window's load event
    window.addEventListener('load', handleLoad);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);


  useEffect(() => {


    const fetchAllProperties = async () => {
      const data = await request(`/property/getAll`, 'GET');
      setAllProperties(data);

      data.map((property) => {
        preloadImage("https://katteochapi.onrender.com/images/" + property.img)
        return "ok"

      })
    };

    fetchAllProperties();



  }, []);


  useEffect(() => {

    console.log("All properties:" + allProperties)

  }, [allProperties])




  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });




  const handleSearch = () => {
    navigate(`/properties`);
  };

  const handleContact = () => {
    navigate(`/contact`);
  };

  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };





  useEffect(() => {

    const fetchWebsite = async () => {
      const data = await request(`/website/getAll`, 'GET');

      dispatch(getData(data[0]))

      console.log("Dispatched")
      document.title = data[0].tabTitle
      setBlob(data[0].logoImg)
      setOg(data[0].ogImg)

    };



    fetchWebsite();

  }, []);


  const [jsxElements, setJsxElements] = useState((<>
    <h2> {reduxData.websiteTitle} </h2>
    <p>
      {reduxData.landingHeroDesc}
    </p>
    {reduxData.data.landingHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.landingHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton" >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.landingHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.landingHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton">
            RENT
          </button>

        )

        }

        {reduxData.data.landingHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.landingHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }
  </>))


  const [jsxElements2, setJsxElements2] = useState((<>
    <h2> {reduxData.data.whySellHeroTitle} </h2>
      <p>
      {reduxData.data.whySellHeroDesc}
      </p>
      {reduxData.data.whySellHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.whySellHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton">
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.whySellHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.whySellHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton" >
            RENT
          </button>

        )

        }

        {reduxData.data.whySellHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.whySellHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }

  </>))

  const [jsxElements3, setJsxElements3] = useState((<>
    <h2>{reduxData.data.whyLetHeroTitle}</h2>
    <p>
    {reduxData.data.whyLetHeroDesc}

    </p>
    {reduxData.data.whyLetHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.whyLetHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton" >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.whyLetHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.whyLetHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton" >
            RENT
          </button>

        )

        }

        {reduxData.data.whyLetHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.whyLetHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }
  </>))





  useEffect(() => {


    setJsxElements((<>
      <h2> {reduxData.data.landingHeroTitle} </h2>
      <p>
        {reduxData.data.landingHeroDesc}
      </p>
      {reduxData.data.landingHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.landingHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton" >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.landingHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.landingHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton">
            RENT
          </button>

        )

        }

        {reduxData.data.landingHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.landingHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }
    </>))

  }, [reduxData])


  useEffect(() => {


    setJsxElements2((<>
      <h2> {reduxData.data.whySellHeroTitle} </h2>
      <p>
      {reduxData.data.whySellHeroDesc}
      </p>
      {reduxData.data.whySellHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.whySellHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton">
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.whySellHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.whySellHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton" >
            RENT
          </button>

        )

        }

        {reduxData.data.whySellHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.whySellHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }
      
      
    </>))
  
  }, [reduxData])


  
  useEffect(() => {


    setJsxElements3((<>
      <h2> {reduxData.data.whyLetHeroTitle} </h2>
      <p>
      {reduxData.data.whyLetHeroDesc}
      </p>
      {reduxData.data.whyLetHeroButtonType === "Properties" && (
          <button onClick={handleSearch} className="valButton" >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.whyLetHeroButtonType === "OurTeam" && (
          <button onClick={handleContact} className="valButton" >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.whyLetHeroButtonType === "About" && (

          <button onClick={handleAbout} className="valButton" >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.whyLetHeroButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className="valButton" >
            RENT
          </button>

        )

        }

        {reduxData.data.whyLetHeroButtonType === "WhySell" && (
          <button onClick={handleWhySell} className="valButton" >
            SELL
          </button>

        )


        }

        {reduxData.data.whyLetHeroButtonType === "Valuation" && (
          <button onClick={handleValuation} className="valButton" >
            GET A VALUATION
          </button>

        )


        }
    </>))
  
  }, [reduxData])













  return (







    <div style={{ visibility: isLoaded ? "visible" : "hidden" }}>


      <ScrollToTop>

   



        <Routes>
          <Route path="/" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <Slideshow jsxProp={jsxElements} />
              <WhyKStrip />
              <Tiles />
              <TeamStrip />
              {/* <PropertySlider/>*/}
              <ReviewStrip />

              <Footer />
            </div>
          } />

          <Route path="/stampduty" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <StampBanner />
              <StampDuty />
              <Footer />
            </div>
          } />

          <Route path="/valuation" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={false} />
              <Valuation />
              <Footer />
            </div>
          } />

          <Route path="/whysell" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <Slideshow jsxProp={jsxElements2} />
              <WhySell />
              <ReviewStrip />
           
              <TeamStrip />

              <Footer />
            </div>
          } />


          <Route path="/whylet" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <Slideshow jsxProp={jsxElements3} />

              <div className="specialDissapearance">
                <WhyLetInfo />
              </div>
              <WhyLet />
              <ReviewStrip />
              <TeamStrip />

              <Footer />
            </div >
          } />


          <Route path="/terms-and-conditions" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={false} />
              <TsAndCs />

              <Footer />
            </div>
          } />

          <Route path="/privacyNotice" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={false} />
              <PrivacyNotice />

              <Footer />
            </div>
          } />



          <Route path="/contact" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <ContactBanner />
              <ContactInfo />
              <Contact />

              <ContactHero />

              <ReviewStrip />
              <Footer />
            </div>
          } />

          <Route path="/userDetail/:id" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={false} />
              <AgentDetail />
              <RentOrSell />
              <Footer />

            </div>
          } />


          <Route path="/about" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <AboutBanner />

              <About />

              
              <TeamStrip />
              <Footer />

            </div>
          } />

          <Route path="/reviews" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={true} />
              <ReviewBanner />
              <Reviews />
             
              <Footer />
            </div>
          } />


          <Route path="/properties" element={
            <div className="surrounder">

              <Navbar2 scrollEvent={false} />

              <Properties properties={allProperties} />
              <RentOrSell />
              <Footer />
            </div>
          } />
          <Route path="/propertyDetail/:id" element={

            <div className="surrounder">
              <Navbar2 scrollEvent={false} />
              <PropertyDetail />
              <RentOrSell />
              <Footer />
            </div>} />

          <Route path="/employee-signup" element={<Signup />} />

          <Route path="/employee-signin" element={<Signin />} />



          <Route path="/employee-dashboard" element={<Dashboard />} />

        </Routes>
      </ScrollToTop>


    </div>


  );

}


export default App;
