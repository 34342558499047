import React from 'react'
import { useState } from 'react'
import classes from "./valuation.module.css";
import { useSelector } from 'react-redux';
import emailjs from '@emailjs/browser'
import { useRef } from 'react'

const Valuation = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const reduxData = useSelector((state) => state.website);

  const [success, setSuccess] = useState(false)
  const formRef = useRef()
  const [desc, setDesc] = useState("")
  const [name, setName] = useState()
  const [email, setEmail] = useState()



  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  preloadImage(PF + "oldbatch6.jpg")




  const serviceId = process.env.REACT_APP_SERVICE_ID
  const templateId = process.env.REACT_APP_TEMPLATE_ID
  const publicKey = process.env.REACT_APP_PUBLIC_KEY


  const handleContactOwner = async (e) => {
    e.preventDefault()

    emailjs.sendForm(serviceId, templateId, formRef.current, publicKey)
      .then((result) => {

        setSuccess(true)
        setDesc("")
        setName("")
        setEmail("")
        setTimeout(() => {
          setSuccess(false)
        }, 1100)
      }, (error) => {
        console.log(error.text);
      });
  }



  return (




    <div style={{ backgroundImage: `url('${PF + "oldbatch6.jpg"}')` }} className={classes.contactForm}>
      <div className={classes.contactFormWrapper} onClick={(e) => e.stopPropagation()}>
        <h2>Book a valuation</h2>

        <p className={classes.mess}>{reduxData.data.valuationDesc}</p>

        <form onSubmit={handleContactOwner} ref={formRef}>
          <input
            type="text"
            value={email}
            placeholder='My email'
            name="from_email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            value={name}
            placeholder='My name'
            name="from_name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            defaultValue="admin@kateeoh.com" // Use defaultValue instead of value
            style={{ display: "none" }}
           
            name="to_email"
          />
          <textarea
            className={classes.area}
            value={desc}
            placeholder='Message'
            name="message"
            onChange={(e) => setDesc(e.target.value)}
          />
          <button className={classes.getBtn}>Send</button>
        </form>

        {success === true ? (

          <div className={`${classes.success} ${classes.show}`}>
            You've successfully contacted the estate agent!
          </div>
        ) : (
          <div className={classes.success}>
            You've successfully contacted the estate agent!
          </div>


        )}

      </div>



    </div>


  )
}

export default Valuation