import React, { useState } from 'react'
import classes from './signin.module.css'
import { useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { request } from '../../util/fetchAPI'
import { login } from '../../redux/authSlice'


const Signin = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const reduxData = useSelector((state) => state.website);

  const handleLogin = async (e) => {
    e.preventDefault();

   

    try {
      const options = {
        'Content-Type': 'application/json'
      }

      const data = await request('/auth/login', "POST", options, { email, password })
      dispatch(login(data))
      navigate("/employee-dashboard")

    } catch (error) {
      console.error(error)
      alert("Invalid email or password.");
    }

  }

  return (
    <div style={{ backgroundImage: `url('${PF + "beauty.webp"}')` }} className={classes.container}>



      <div className={classes.topBar}>
        <div className={classes.section}>
          <img alt="logo" src={`https://katteochapi.onrender.com/images/${reduxData.data.logoImg}`} />
          <h1>Real Estate Agents</h1>
        </div>
      </div>

      <div className={classes.wrapper}>

        <h2>Sign in</h2>
        <form onSubmit={handleLogin}>
          <input type="email" placeholder="Email..." onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Password..." onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Sign in</button>
         
        </form>
      </div>




    </div>
  )
}

export default Signin