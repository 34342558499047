import React from 'react'
import classes from "./stampBanner.module.css"
import { AiFillStar } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux'


import { useNavigate } from 'react-router-dom';

const StampBanner = () => {

  const navigate = useNavigate()
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  preloadImage(PF + "office.jpg")


  const reduxData = useSelector((state) => state.website);


  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };




  return (
    <div className={classes.container}>
      <div className={classes.abImg}>
        <img src={`https://katteochapi.onrender.com/images/${reduxData.data.stampDutyHeroImg}`} alt="background" />
      </div>
      <div className={classes.absolute}>
        <div className={classes.left}>
          <h2>{reduxData.data.stampDutyHeroTitle}</h2>
          <p>
            {reduxData.data.stampDutyHeroDesc}
          </p>
          {reduxData.data.stampDutyHeroButtonType === "Properties" && (
            <button onClick={handleSearch} className={classes.valButton} >
              OUR PORTFOLIO
            </button>
          )


          }


          {reduxData.data.stampDutyHeroButtonType === "OurTeam" && (
            <button onClick={handleContact} className={classes.valButton} >
              MEET THE TEAM
            </button>
          )


          }

          {reduxData.data.stampDutyHeroButtonType === "About" && (

            <button onClick={handleAbout} className={classes.valButton} >
              OUR MISSION
            </button>
          )


          }

          {reduxData.data.stampDutyHeroButtonType === "WhyLet" && (
            <button onClick={handleWhyLet} className={classes.valButton} >
              RENT
            </button>

          )

          }

          {reduxData.data.stampDutyHeroButtonType === "WhySell" && (
            <button onClick={handleWhySell} className={classes.valButton} >
              SELL
            </button>

          )


          }

          {reduxData.data.stampDutyHeroButtonType === "Valuation" && (
            <button onClick={handleValuation} className={classes.valButton} >
              GET A VALUATION
            </button>

          )


          }






      

        </div>
      </div>
    </div>
  )
}

export default StampBanner