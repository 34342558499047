import React, { useEffect, useRef } from 'react';
import loadjs from 'loadjs';

const MapWithMarker = ({ address }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  const googleAPI = process.env.REACT_APP_GOOGLE_API;

  useEffect(() => {
    // Load the Google Maps API
    const loadMap = () => {
      loadjs(`https://maps.googleapis.com/maps/api/js?key=${googleAPI}&libraries=places`, {
        success: initMap,
        async: true,
      });
    };

    // Initialize the map
    const initMap = () => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const { lat, lng } = results[0].geometry.location;
          const mapOptions = {
            center: { lat: lat(), lng: lng() },
            zoom: 15,
          };
          mapRef.current = new window.google.maps.Map(mapContainerRef.current, mapOptions);
          markerRef.current = new window.google.maps.Marker({
            position: { lat: lat(), lng: lng() },
            map: mapRef.current,
          });

          // Perform a nearby search for places
          const placesService = new window.google.maps.places.PlacesService(mapRef.current);
          const request = {
            location: { lat: lat(), lng: lng() },
            radius: 500,
            type: ['restaurant', 'cafe', 'park', 'museum'], // Adjust the types of places as needed
          };
          placesService.nearbySearch(request, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              for (let i = 0; i < results.length; i++) {
                createMarker(results[i]);
              }
            }
          });
        } else {
          console.error('Geocode was not successful for the following reason:', status);
        }
      });
    };

    // Create a marker with popup for each place
    const createMarker = (place) => {
      const marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: mapRef.current,
        title: place.name,
        icon: {
          url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png', // Change the URL to the desired marker icon
        },
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div><strong>${place.name}</strong><br>${place.vicinity}</div>`,
      });

      marker.addListener('click', () => {
        infoWindow.open(mapRef.current, marker);
      });
    };

    loadMap();

    // Cleanup
    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
    };
  }, [address]);

  return <div ref={mapContainerRef} style={{ height: '400px' }} />;
};

export default MapWithMarker;