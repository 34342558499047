import React, { useEffect, useState } from 'react'
import classes from "./adminDisplayUserEdit.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../../util/fetchAPI'


const AdminDisplayUserEdit = ({ property, closeModal, fetchFunction }) => {

    const { token, user } = useSelector((state) => state.auth)




    const navigate = useNavigate()


    const [state, setState] = useState({})




    const [photo, setPhoto] = useState("")




















    useEffect(() => {
        setState({
            ...property,
        });


        console.log(property);


    }, [property]);










    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })


    }





















    const handleEditDisplayUser = async (e) => {
        e.preventDefault();


        let filename = null;



        if (photo) {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename = state.profileImg
        }






        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }







            let data = await request(`/displayUsers/${property?._id}`, 'PUT', options, {
                ...state,

                profileImg: filename,

            })








            fetchFunction();

            alert("Your Display User has been successfully updated!")
            closeModal();

        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }













    return (

        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
            <h2>Edit Property</h2>
            <form onSubmit={handleEditDisplayUser}>
                <div className={classes.inputs}>
                    <label>
                        <span>Username:</span>
                        <input type="text" value={state.username} placeholder='Username...' name='username' onChange={handleState} />

                    </label>

                    <label>
                        <span>Role:</span>
                        <input type="text" placeholder='Role...' value={state.role} name='role' onChange={handleState} />

                    </label>






                </div>



                <h3 className={classes.scripTitle}>Property description:</h3>
                <textarea className={classes.scrip} type="text" value={state.desc} name='desc' onChange={handleState} />





                <h3 className={classes.scripTitle}>Photos:</h3>
                <div className={classes.types2}>

                    <div className={classes.upload} >
                        <label className={classes.btnUpload2} htmlFor="photoEdit">Main Image</label>
                        <input
                            id="photoEdit"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={(e) => { setPhoto(e.target.files[0]) }}
                        />

                        {photo === "" ? (


                            <img src={`https://katteochapi.onrender.com/images/${state?.profileImg}`} style={{ width: '80px', height: '80px', borderRadius: "50%", objectFit: "cover" }} alt="Uploaded" />



                        ) : (
                            <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                        )}
                    </div>










                </div>







                <button className={classes.formButton2} type="submit">Update Display User</button>
            </form>



        </div>


    )
}

export default AdminDisplayUserEdit