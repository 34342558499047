import React, { useState, useEffect, useRef } from 'react';
import { TiChevronLeftOutline, TiChevronRightOutline } from 'react-icons/ti';
import "./threeDCarousel.css"
import { request } from '../../util/fetchAPI';
import { Link } from 'react-router-dom';

const MAX_VISIBILITY = 1;



const Card = React.memo(({ title, content, img }) => (
  <div className='card'>
    <img src={`https://katteochapi.onrender.com/images/${img}`} alt="headshot" />
    <h2>{title}</h2>
    <p className="content">
  {`${content.split(' ').slice(0, 15).join(' ')}${
    content.split(' ').length > 10 ? '...' : ''
  }`}
</p>

  </div>
));

const Carousel = ({ children }) => {
  const [active, setActive] = useState(1);
  const count = children.length;

  const handlePrev = () => {
    setActive((prevActive) => Math.max(prevActive - 1, 0));
  };

  const handleNext = () => {
    setActive((prevActive) => Math.min(prevActive + 1, count - 1));
  };

  return (
    <div className='carousel'>
      {active > 0 && (
        <button className='nav left' onClick={handlePrev}>
          <TiChevronLeftOutline />
        </button>
      )}
      {children.map((child, i) => (
        <div
          key={i}
          className='card-container'
          style={{
            '--active': i === active ? 1 : 0,
            '--offset': (active - i) / 3,
            '--direction': Math.sign(active - i),
            '--abs-offset': Math.abs(active - i) / 3,
            pointerEvents: active === i ? 'auto' : 'none',
            opacity: Math.abs(active - i) >= MAX_VISIBILITY ? 0 : 1,
            display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
          }}
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className='nav right' onClick={handleNext}>
          <TiChevronRightOutline />
        </button>
      )}
    </div>
  );
};

const ThreeDCarousel = () => {
  const [allAgents, setAllAgents] = useState(new Set());


  const carouselRef = useRef([]);
  useEffect(() => {
    const appearOptions = {
      threshold: 0.48,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    carouselRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

   
  

    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


  useEffect(() => {
    const fetchAllAgents = async () => {
      try {
        const data = await request('/displayUsers/getAll', 'GET');
        setAllAgents(new Set(data));
      } catch (error) {
        console.error('Error fetching agents:', error);
      }
    };

    fetchAllAgents();
  }, []);

  return (
    <div className='app' ref={(el) => (carouselRef.current[0] = el)}>
      <Carousel>
        {[...allAgents].map((user) => (
          <Link to={`/userDetail/${user._id}`} key={user._id}>
            <Card title={user.username} img={user.profileImg} content={user.role} />
          </Link>
        ))}
      </Carousel>
    </div>
  );
};

export default ThreeDCarousel;
