import React from 'react';
import classes from './tiles.module.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};

const PF = process.env.REACT_APP_PUBLIC_FOLDER;
preloadImage(PF + "background1.webp");
preloadImage(PF + "about.webp");
preloadImage(PF + "kitchen1.webp");
preloadImage(PF + "reviews.webp");
preloadImage(PF + "office.webp");
preloadImage(PF + 'DanPic.webp');
preloadImage(PF + 'stairs2.webp');
preloadImage(PF + 'front.webp');
preloadImage(PF + "scan1.webp");
preloadImage(PF + "beauty.webp");
preloadImage(PF + "beauty2.webp");
preloadImage(PF + "beauty3.webp");
preloadImage(PF + "fullview.webp");




const Tiles = () => {
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/properties`);
  };

  const handleContact = () => {
    navigate(`/contact`);
  };

  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };





  const PF = process.env.REACT_APP_PUBLIC_FOLDER;



  const reduxData = useSelector((state) => state.website);



  return (
    <div className={classes.container}>
      <div className={classes.wrapper} >
        <div className={classes.row} >
          <div className={classes.left}   >
            <img alt="" className={classes.img} src={`https://katteochapi.onrender.com/images/${reduxData.data.tilesImg1}`} />

          </div>
          <div className={classes.right} >
            <div className={`${classes.desc}`}  >
              <h3>{reduxData.data.tilesTitle1}</h3>
              <p>
               {reduxData.data.tilesDesc1}
              </p>
           
              {reduxData.data.tilesButtonType1 === "Properties" && (
                <button onClick={handleSearch} className={classes.bouton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.tilesButtonType1 === "OurTeam" && (
                <button onClick={handleContact} className={classes.bouton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.tilesButtonType1 === "About" && (

                <button onClick={handleAbout} className={classes.bouton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.tilesButtonType1 === "WhyLet" && (
                 <button onClick={handleWhyLet} className={classes.bouton} >
                 RENT
               </button>

              )

              }

              {reduxData.data.tilesButtonType1 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.bouton} >
                SELL
              </button>

              )


              }

              {reduxData.data.tilesButtonType1 === "Valuation" && (
                 <button onClick={handleValuation} className={classes.bouton} >
                 Get a Valuation
               </button>

              )


              }

          

            </div>
          </div>
        </div>
        <div className={classes.row}  >
          <div className={classes.left} >
            <div className={`${classes.desc}`} >
            <h3>{reduxData.data.tilesTitle2}</h3>
              <p>
               {reduxData.data.tilesDesc2}
              </p>
           
              {reduxData.data.tilesButtonType2 === "Properties" && (
                <button onClick={handleSearch} className={classes.bouton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.tilesButtonType2 === "OurTeam" && (
                <button onClick={handleContact} className={classes.bouton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.tilesButtonType2 === "About" && (

                <button onClick={handleAbout} className={classes.bouton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.tilesButtonType2 === "WhyLet" && (
                 <button onClick={handleWhyLet} className={classes.bouton} >
                 RENT
               </button>

              )

              }

              {reduxData.data.tilesButtonType2 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.bouton} >
                SELL
              </button>

              )


              }

              {reduxData.data.tilesButtonType2 === "Valuation" && (
                 <button onClick={handleValuation} className={classes.bouton} >
                 Get a Valuation
               </button>

              )


              }
            </div>
          </div>
          <div className={classes.right} >
            <img className={classes.img} alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.tilesImg2}`} />
          </div>
        </div>
        <div className={classes.row}  >
          <div className={classes.left}   >
            <img alt="" className={classes.img} src={`https://katteochapi.onrender.com/images/${reduxData.data.tilesImg3}`} />
          </div>
          <div className={classes.right}>
            <div className={`${classes.desc}`}>
            <h3>{reduxData.data.tilesTitle3}</h3>
              <p>
               {reduxData.data.tilesDesc3}
              </p>
           
              {reduxData.data.tilesButtonType3 === "Properties" && (
                <button onClick={handleSearch} className={classes.bouton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.tilesButtonType3 === "OurTeam" && (
                <button onClick={handleContact} className={classes.bouton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.tilesButtonType3 === "About" && (

                <button onClick={handleAbout} className={classes.bouton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.tilesButtonType3 === "WhyLet" && (
                 <button onClick={handleWhyLet} className={classes.bouton} >
                 RENT
               </button>

              )

              }

              {reduxData.data.tilesButtonType3 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.bouton} >
                SELL
              </button>

              )


              }

              {reduxData.data.tilesButtonType3 === "Valuation" && (
                 <button onClick={handleValuation} className={classes.bouton} >
                 Get a Valuation
               </button>

              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiles;