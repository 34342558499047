import React, {useEffect, useRef} from 'react'
import classes from './about.module.css'
import { useDispatch, useSelector } from 'react-redux'


const About = () => {



  const descRef = useRef([]);

  const reduxData = useSelector((state) => state.website);

 


  useEffect(() => {
    const appearOptions = {
      threshold: 0.3,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });


   

    return () => {
      appearOnScroll.disconnect();
    };
  }, []);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
      <div className={classes.sideWrap}  ref={(el) => (descRef.current[0] = el)}>
        <h2>{reduxData.data.aboutMainTitle}</h2>
        <p className={classes.p1}>{reduxData.data.aboutMainDesc}</p>
        <br/>
        <p className={classes.extraText}>
        {reduxData.data.aboutMainParagraph1}
</p></div>
        <img src={`https://katteochapi.onrender.com/images/${reduxData.data.aboutMainImg}`} alt="this" />
        
      </div>
      <div className={classes.wrapperTwo}  ref={(el) => (descRef.current[1] = el)}>
        <div className={classes.text}>
          <p>
          {reduxData.data.aboutMainParagraph2}


          </p>

        </div>
        <div className={classes.text}>
        <p>
        {reduxData.data.aboutMainParagraph3}
          </p>

        </div>
      </div>
    </div>
  )
}

export default About