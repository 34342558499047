import React from 'react'
import classes from "./aboutBanner.module.css"
import { AiFillStar } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const AboutBanner = () => {


  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const reduxData = useSelector((state) => state.website);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };

  preloadImage(PF + "oldbatch4.jpg")

  const navigate = useNavigate()

  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };


  return (
    <div className={classes.container}>
      <div className={classes.abImg}>
        <img src={`https://katteochapi.onrender.com/images/${reduxData.data.aboutHeroImg}`} alt="background" />
      </div>
      <div className={classes.absolute}>
        <div className={classes.left}>
          <h2>{reduxData.data.aboutHeroTitle}</h2>
          <p>
          {reduxData.data.aboutHeroDesc}</p>
       
          {reduxData.data.aboutHeroButtonType === "Properties" && (
            <button onClick={handleSearch} className={classes.valButtonAb} >
              OUR PORTFOLIO
            </button>
          )


          }


          {reduxData.data.aboutHeroButtonType === "OurTeam" && (
            <button onClick={handleContact} className={classes.valButtonAb} >
              MEET THE TEAM
            </button>
          )


          }

          {reduxData.data.aboutHeroButtonType === "About" && (

            <button onClick={handleAbout} className={classes.valButtonAb} >
              OUR MISSION
            </button>
          )


          }

          {reduxData.data.aboutHeroButtonType === "WhyLet" && (
            <button onClick={handleWhyLet} className={classes.valButtonAb} >
              RENT
            </button>

          )

          }

          {reduxData.data.aboutHeroButtonType === "WhySell" && (
            <button onClick={handleWhySell} className={classes.valButtonAb} >
              SELL
            </button>

          )


          }

          {reduxData.data.aboutHeroButtonType === "Valuation" && (
            <button onClick={handleValuation} className={classes.valButtonAb} >
              GET A VALUATION
            </button>

          )


          }

        </div>
      </div>
    </div>
  )
}

export default AboutBanner