import React, { useEffect, useRef } from 'react'
import classes from './whyLet.module.css'

import { useDispatch, useSelector } from 'react-redux'


import { useNavigate } from 'react-router-dom';

const WhyLet = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const reduxData = useSelector((state) => state.website);

  const descRef = useRef([]);

  const imgRef = useRef([]);


  useEffect(() => {
    const appearOptions = {
      threshold: 0.05,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });


    imgRef.current.forEach((img) => {
      appearOnScroll.observe(img);
    });

    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


  const navigate = useNavigate()

  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleSearch = () => {
    navigate(`/properties`);
  };



  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };


  return (
    <div className={classes.container}>
      <div className={classes.wrapper}  >
        <h4 >{reduxData.data.whyLetPanelsHeaderTitle}</h4>
        <p>  {reduxData.data.whyLetPanelsHeaderDesc} </p>
        <div className={classes.box}>

          <div className={classes.row} ref={(el) => (imgRef.current[0] = el)}>
            <div className={classes.imgContainer}>
              <img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.whyLetPanelsImg1}`} />
            </div>
            <div className={classes.alignRight} >
              <h5>{reduxData.data.whyLetPanelsTitle1}</h5>
              <p>
              {reduxData.data.whyLetPanelsDesc1} </p>
              {reduxData.data.whyLetPanelsButton1 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnLet} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetPanelsButton1 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnLet} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton1 === "About" && (

                <button onClick={handleAbout} className={classes.btnLet} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton1 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnLet} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetPanelsButton1 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnLet} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetPanelsButton1 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnLet} >
                  GET A VALUATION
                </button>

              )


              }
            </div>
          </div>
          <div id={classes.dis} className={classes.row} ref={(el) => (imgRef.current[1] = el)}>
            <div className={classes.alignLeft} >

              <h5>{reduxData.data.whyLetPanelsTitle2}</h5>
              <p>{reduxData.data.whyLetPanelsDesc2}
              </p>
              {reduxData.data.whyLetPanelsButton2 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnLet} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetPanelsButton2 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnLet} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton2 === "About" && (

                <button onClick={handleAbout} className={classes.btnLet} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton2 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnLet} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetPanelsButton2 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnLet} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetPanelsButton2 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnLet} >
                  GET A VALUATION
                </button>

              )


              }
            </div>
            <div className={classes.imgContainer}>
            <img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.whyLetPanelsImg2}`} />

            </div>
          </div>


          <div className={classes.row} ref={(el) => (imgRef.current[2] = el)}>
            <div className={classes.imgContainer}>
            <img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.whyLetPanelsImg3}`} />

            </div>
            <div className={classes.alignRight}>
              <h5>{reduxData.data.whyLetPanelsTitle3}</h5>
              <p>{reduxData.data.whyLetPanelsDesc3}</p>
              {reduxData.data.whyLetPanelsButton3 === "Properties" && (
                <button onClick={handleSearch} className={classes.btnLet} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetPanelsButton3 === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnLet} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton3 === "About" && (

                <button onClick={handleAbout} className={classes.btnLet} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetPanelsButton3 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnLet} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetPanelsButton3 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnLet} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetPanelsButton3 === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnLet} >
                  GET A VALUATION
                </button>

              )


              }
            </div>

          </div>
        </div>

        <h6>{reduxData.data.whyLetPanelsBottomCaption}</h6>
        {reduxData.data.whyLetPanelsBottomButtonType === "Properties" && (
                <button onClick={handleSearch} className={classes.btnLet} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetPanelsBottomButtonType === "OurTeam" && (
                <button onClick={handleContact} className={classes.btnLet} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetPanelsBottomButtonType === "About" && (

                <button onClick={handleAbout} className={classes.btnLet} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetPanelsBottomButtonType === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.btnLet} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetPanelsBottomButtonType === "WhySell" && (
                <button onClick={handleWhySell} className={classes.btnLet} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetPanelsBottomButtonType === "Valuation" && (
                <button onClick={handleValuation} className={classes.btnLet} >
                  GET A VALUATION
                </button>

              )


              }
      </div>
    </div>
  )
}

export default WhyLet