import React, { useEffect, useRef } from 'react'
import classes from "./teamStrip.module.css"
import ThreeDCarousel from '../threeDCarousel/ThreeDCarousel'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'





const TeamStrip = () => {


  const descRef = useRef([]);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const navigate = useNavigate()

  const handleSearch = () => {
    navigate(`/properties`);
  };

  const handleContact = () => {
    navigate(`/contact`);
  };

  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };


  
  const reduxData = useSelector((state) => state.website);


  useEffect(() => {
    const appearOptions = {
      threshold: 0.05,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });



    return () => {
      appearOnScroll.disconnect();
    };
  }, []);





  return (
    <div style={{ backgroundImage: `url('https://katteochapi.onrender.com/images/${reduxData.data.teamBannerBackgroundImg}')` }} className={classes.container}>
      <div className={classes.wrapper} ref={(el) => (descRef.current[0] = el)}>

        <h3>{reduxData.data.teamBannerTitle}</h3>

        <p>{reduxData.data.teamBannerDesc}</p>

        {reduxData.data.teamBannerButtonType === "Properties" && (
          <button onClick={handleSearch} className={classes.teamBtn} >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.teamBannerButtonType === "OurTeam" && (
          <button onClick={handleContact} className={classes.teamBtn} >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.teamBannerButtonType === "About" && (

          <button onClick={handleAbout} className={classes.teamBtn} >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.teamBannerButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className={classes.teamBtn} >
            RENT
          </button>

        )

        }

        {reduxData.data.teamBannerButtonType === "WhySell" && (
          <button onClick={handleWhySell} className={classes.teamBtn} >
            SELL
          </button>

        )


        }

        {reduxData.data.teamBannerButtonType === "Valuation" && (
          <button onClick={handleValuation} className={classes.teamBtn} >
            Get a Valuation
          </button>

        )


        }
      </div>
      <div className={classes.carouselDiv}>

        <ThreeDCarousel />

      </div>

    </div>
  )
}

export default TeamStrip