import React, { useState, useEffect } from "react";
import classes from "./navbar2.module.css"

import { AiOutlineSearch } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'


const NavBar2 = ({ scrollEvent, buttonConfirm  }) => {


  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const [click, setClick] = useState(false);

  const [fontColor, setFontColor] = useState(scrollEvent ? 'white' : 'black');
  

  const handleClick = () => setClick(!click);
  const [searchQuery, setSearchQuery] = useState("")

  const Close = () => setClick(false);
  const navigate = useNavigate()


  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };
preloadImage(PF + "knobg.webp");




const reduxData = useSelector((state) => state.website);
 



  const [styles, setStyles] = useState(scrollEvent ? {
    borderBottom: '0',
    backgroundColor: 'transparent',
    zIndex: '9999'

  } : {
    borderBottom: '0.75px solid #D3d3d3',
    zIndex: '9999999',
    backgroundColor: 'white'

  });


  useEffect(() => {

    setStyles(scrollEvent ? {
      borderBottom: '0',
      backgroundColor: 'transparent',
      zIndex: '9999'

    } : {
      borderBottom: '0.75px solid #D3d3d3',
      zIndex: '9999999',
      backgroundColor: 'white'

    })

    setFontColor(scrollEvent ? 'white' : 'black')



  }, [scrollEvent])




  const listenScrollEvent = () => {
    if (window.scrollY > 30) {

      setFontColor('black');
      setStyles({
        borderBottom: '0.75px solid #D3d3d3',
        zIndex: '9999999',
        backgroundColor: 'white'
      });
    } else {

      setFontColor('white');
      setStyles({
        borderBottom: '0',
        backgroundColor: 'transparent',
        zIndex: '9999'
      });
    }
  };

  useEffect(() => {

    if (scrollEvent === true) {
      window.addEventListener('scroll', listenScrollEvent);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('scroll', listenScrollEvent);
      };

    }
  }, [scrollEvent]);
















  const handleLetSearch = () => {
    //navigate to properties
    navigate(`/properties?propType=&contType=&search=`)
  }


  const handleSellSearch = () => {
    //navigate to properties
    navigate(`/properties?propType=&contType=&search=`)
  }


  const handleSearch = () => {
    //navigate to properties
    navigate(`/properties?propType=&contType=&search=`)
  }

  const handleQuerySearch = () => {
    //navigate to properties
    navigate(`/properties?propType=&contType=&search=${searchQuery}`)


  }


  const handleContact = () => {
    //navigate to properties
    navigate(`/contact`)
  }

  const handleAbout = () => {
    //navigate to properties
    navigate(`/about`)
  }

  const handleReview = () => {
    //navigate to properties
    navigate(`/reviews`)
  }



  const handleStamp = () => {
    //navigate to properties
    navigate(`/stampduty`)
  }

  const handleValuation = () => {
    //navigate to properties

    navigate(`/valuation`)

  }

  const handleWhyLet = () => {
    //navigate to properties

    navigate(`/whylet`)

  }

  const handleWhySell = () => {
    //navigate to properties

    navigate(`/whysell`)

  }
























  return (
    <div className={classes.container}>
     
      <div className={click ? classes.mainContainer : ""} onClick={() => Close()} />
      <nav className={classes.navbar} onClick={e => e.stopPropagation()} style={styles}>
        <div className={classes.navContainer}>

          <Link className={classes.navLogo} to='/'><img alt="" src={`https://katteochapi.onrender.com/images/${reduxData.data.logoImg}`} /></Link>


          <div className={classes.navItem}>
            <AiOutlineSearch style={{ color: fontColor }} className={classes.searchIcon} />

            <div className={classes.navDrop2}>
              <input className={classes.searchInput} onChange={e => setSearchQuery(e.target.value)} />
              <AiOutlineSearch onClick={handleQuerySearch} />
            </div>
          </div>

          <ul className={`${classes.navMenu} ${click ? classes.active1 : ''}`}>
            <li style={{ color: fontColor }} className={classes.navItem}>

              BUY

              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleSellSearch()
                }}>Property Search</li>
                <li onClick={() => {
                  Close()
                  handleStamp()
                }}> Stamp Duty Calculator</li>
                <li onClick={() => {
                  Close()
                  handleValuation()
                }}>Book a Free Valuation</li>
              </ul>

            </li>
            <li style={{ color: fontColor }} className={classes.navItem}>

              RENT
              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleLetSearch()
                }}>Property Search</li>
                <li onClick={() => {
                  Close()
                  handleValuation()
                }}>Book a Free Valuation</li>
              </ul>

            </li>
            <li style={{ color: fontColor }} className={classes.navItem}>

              SELL
              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleWhySell()
                }}>Why sell with K?</li>
                <li onClick={() => {
                  Close()
                  handleValuation()
                }}>Get a Free Valuation</li>
              </ul>


            </li>

            <li style={{ color: fontColor }} className={classes.navItem}>
              LANDLORDS
              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleWhyLet()
                }}>Why let with K?</li>

                <li onClick={() => {
                  Close()
                  handleValuation()
                }}>Get a Free Valuation</li>
              </ul>



            </li>
            <li style={{ color: fontColor }} className={classes.navItem}>
              ABOUT
              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleAbout()
                }}>Our mission</li>
                <li onClick={() => {
                  Close()
                  handleContact()
                }}>Meet the team</li>
                <li onClick={() => {
                  Close()
                  handleReview()
                }}>Read our reviews</li>
              </ul>


            </li>
            <li style={{ color: fontColor }} id={classes.areaG} className={classes.navItem}>
              AREA GUIDE

              {/*eventually here, you can generate areas based on prop locations*/}

              <ul className={classes.navDrop}>
                <li onClick={() => {
                  Close()
                  handleSearch()
                }
                }>All areas</li>

              </ul>



            </li>
            <li onClick={() => {
              Close()
              handleContact()
            }} style={{ color: fontColor }} className={classes.navItem}>
              CONTACT

            </li>
          </ul>


          <div className={classes.navIcon} onClick={handleClick}>
            <GiHamburgerMenu className={classes.burger} />
          </div>

          <button onClick={handleValuation} className={classes.button}>GET A VALUATION</button>





        </div>
      </nav>

    </div>

  );
}

export default NavBar2;