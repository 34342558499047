import React, {useEffect, useRef} from 'react'
import classes from "./whyLetInfo.module.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const WhyLetInfo = () => {


  const descRef = useRef([]);

  const reduxData = useSelector((state) => state.website);

  useEffect(() => {
    const appearOptions = {
      threshold: 0.001,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });


    

    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


    const navigate = useNavigate()


    const handleContact = () => {
      //navigate to properties
      navigate(`/contact`)
    }
  
    const handleSearch = () => {
      navigate(`/properties`);
    };
  
  
  
    const handleAbout = () => {
      navigate(`/about`);
    };
  
  
    const handleWhySell = () => {
      navigate(`/whysell`);
    };
  
  
    const handleWhyLet = () => {
      navigate(`/whylet`);
    };
  
  
    const handleValuation = () => {
      navigate(`/valuation`);
    };


  return (
    <div className={classes.container}>
        <div className={classes.wrapper} ref={(el) => (descRef.current[0] = el)}>
            <div className={classes.column}>
                <h3>{reduxData.data.whyLetColumnsTitle1}</h3>
                <p>
                
                {reduxData.data.whyLetColumnsDesc1}
            </p>
            {reduxData.data.whyLetColumnsButtonType1 === "Properties" && (
                <button onClick={handleSearch} className={classes.whyButton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetColumnsButtonType1 === "OurTeam" && (
                <button onClick={handleContact} className={classes.whyButton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType1 === "About" && (

                <button onClick={handleAbout} className={classes.whyButton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType1 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.whyButton} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetColumnsButtonType1 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.whyButton} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetColumnsButtonType1 === "Valuation" && (
                <button onClick={handleValuation} className={classes.whyButton} >
                  GET A VALUATION
                </button>

              )


              }

            </div>

            <div className={classes.column}>
            <h3>{reduxData.data.whyLetColumnsTitle2}</h3>
            <p>
            {reduxData.data.whyLetColumnsDesc2}
            </p>

            {reduxData.data.whyLetColumnsButtonType2 === "Properties" && (
                <button onClick={handleSearch} className={classes.whyButton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetColumnsButtonType2 === "OurTeam" && (
                <button onClick={handleContact} className={classes.whyButton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType2 === "About" && (

                <button onClick={handleAbout} className={classes.whyButton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType2 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.whyButton} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetColumnsButtonType2 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.whyButton} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetColumnsButtonType2=== "Valuation" && (
                <button onClick={handleValuation} className={classes.whyButton} >
                  GET A VALUATION
                </button>

              )


              }
            </div>
            <div className={classes.column}>
            <h3>{reduxData.data.whyLetColumnsTitle3}</h3>
            <p>
           
            {reduxData.data.whyLetColumnsDesc3}
             
            </p>
            {reduxData.data.whyLetColumnsButtonType3 === "Properties" && (
                <button onClick={handleSearch} className={classes.whyButton} >
                  OUR PORTFOLIO
                </button>
              )


              }


              {reduxData.data.whyLetColumnsButtonType3 === "OurTeam" && (
                <button onClick={handleContact} className={classes.whyButton} >
                  MEET THE TEAM
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType3 === "About" && (

                <button onClick={handleAbout} className={classes.whyButton} >
                  OUR MISSION
                </button>
              )


              }

              {reduxData.data.whyLetColumnsButtonType3 === "WhyLet" && (
                <button onClick={handleWhyLet} className={classes.whyButton} >
                  RENT
                </button>

              )

              }

              {reduxData.data.whyLetColumnsButtonType3 === "WhySell" && (
                <button onClick={handleWhySell} className={classes.whyButton} >
                  SELL
                </button>

              )


              }

              {reduxData.data.whyLetColumnsButtonType3 === "Valuation" && (
                <button onClick={handleValuation} className={classes.whyButton} >
                  GET A VALUATION
                </button>

              )


              }

            </div>
            
        </div>
    </div>
  )
}

export default WhyLetInfo