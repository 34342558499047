import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classes from "./adminWebsite.module.css"
import { request } from '../../../util/fetchAPI'
import ClipLoader from "react-spinners/ClipLoader";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";



const AdminWebsite = () => {


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const updateViewportWidth = () => {
            setViewportWidth(window.innerWidth);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', updateViewportWidth);

        // Cleanup: Remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, []); // Empty dependency array means the effect runs once after the initial render

    useEffect(() => {
        // This effect will run whenever the viewport width changes
        console.log('Viewport width changed:', viewportWidth);

        // You can add additional logic or actions here based on viewport changes

    }, [viewportWidth]); // Dependency array with viewportWidth


    const dispatch = useDispatch();


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };




    //AUTHENTICATION

    const { token, user } = useSelector((state) => state.auth)
    const reduxData = useSelector((state) => state.website);



    //SETTING WHICH WEBSITE PANEL IS OPEN

    const [open, setOpen] = useState("");



    const handleOpen = (string) => {
        if (open === string) {
            setOpen("");
        } else {
            setOpen(string);


            setTimeout(() => {
                const element = document.getElementById(string);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 350);
        }
    };




    // INITIALISING STATE AND FETCHING WEBSITE



    const [website, setWebsite] = useState([]);
    const [state, setState] = useState([]);


    const fetchWebsite = async () => {
        const data = await request(`/website/getAll`, 'GET');
        setWebsite(data);
        console.log(data);

    };


    useEffect(() => {

        fetchWebsite();

    }, [])




    useEffect(() => {


        if (website.length > 0) {
            console.log(website[0].ethosTitle)


            setState({
                ...website[0],
            });


            setPhotos(website[0]?.homeImgs);
            setPhotos2(website[0]?.slideshowGallery)


        }



    }, [website])




    //HANDLING TEXTAREA RESIZE!!!



    const refs = useRef({});


    const autoExpand = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };




    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })




        autoExpand(refs.current[name]);


    }






    // HANDLING AN UPDATE


    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [newPhotos, setNewPhotos] = useState([]);
    const [newPhotos2, setNewPhotos2] = useState([]);



    const [photo, setPhoto] = useState("");
    const [photo2, setPhoto2] = useState("");
    const [photo3, setPhoto3] = useState("");
    const [photo4, setPhoto4] = useState("");
    const [photo5, setPhoto5] = useState("");
    const [photo6, setPhoto6] = useState("");
    const [photo7, setPhoto7] = useState("");
    const [photo8, setPhoto8] = useState("");
    const [photo9, setPhoto9] = useState("");
    const [photo10, setPhoto10] = useState("");
    const [photo11, setPhoto11] = useState("");
    const [photo12, setPhoto12] = useState("");
    const [photo13, setPhoto13] = useState("");
    const [photo14, setPhoto14] = useState("");


    const [photo15, setPhoto15] = useState("");
    const [photo16, setPhoto16] = useState("");
    const [photo17, setPhoto17] = useState("");
    const [photo18, setPhoto18] = useState("");
    const [photo19, setPhoto19] = useState("");
    const [photo20, setPhoto20] = useState("");
    const [photo21, setPhoto21] = useState("");
    const [photo22, setPhoto22] = useState("");
    const [photoOg, setPhotoOg] = useState("")




    const [topVideo, setTopVideo] = useState(null)



    const handleVideoChange = (e) => {
        setTopVideo(e.target.files[0]);
    };


    const [extraLogo1Photo, setExtraLogo1Photo] = useState("")
    const [extraLogo2Photo, setExtraLogo2Photo] = useState("")







    const handleWebsite = async (e) => {
        e.preventDefault();
        setPreloader(true)
        window.scrollTo({ top: 0, behavior: 'smooth' });



        let initialPhotos = website[0].slideshowGallery;

        // Current array of photos (new photos might have been added)


        // Function to determine if a photo is new (added to the array)
        function isPhotoEdited(photo, initialPhotos) {
            return !initialPhotos.includes(photo);
        }

        // Separate photos into edited (new) and remaining (existing), while keeping track of their original indices
        let oldPhotos = [...photos2]; // Create a copy of photos2
        let editOldPhotos = [];
        let editOldPhotosIndices = [];
        let remainingOldPhotos = [];
        let remainingOldPhotosIndices = [];

        oldPhotos.forEach((photo, index) => {
            if (isPhotoEdited(photo, initialPhotos)) {
                editOldPhotos.push(photo);
                editOldPhotosIndices.push(index);
            } else {
                remainingOldPhotos.push(photo);
                remainingOldPhotosIndices.push(index);
            }
        });

        let processedPhotos2 = [];

        if (editOldPhotos.length > 0) {
            const uploadPromises = editOldPhotos.map(async (photo, index) => {
                const formData = new FormData();
                const filename = crypto.randomUUID() + '.jpg'; // Assuming the photo has .jpg extension

                processedPhotos2.push({ index, filename });  // Track the filename and the photo index
                formData.append("filename", filename);
                formData.append("image", photo); // Assuming `photo` is the file object; adjust if needed

                await request("/upload/image", "POST", {}, formData, true);
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }

            // Update photos with new filenames
            for (const { index, filename } of processedPhotos2) {
                editOldPhotos[index] = filename;  // Update the photo with the new filename
            }
        }

        // Combine the edited and remaining photos back into the original order
        let combinedPhotos = new Array(oldPhotos.length);

        // Place edited photos back into their original positions
        editOldPhotos.forEach((photo, i) => {
            combinedPhotos[editOldPhotosIndices[i]] = photo;
        });

        // Place remaining photos back into their original positions
        remainingOldPhotos.forEach((photo, i) => {
            combinedPhotos[remainingOldPhotosIndices[i]] = photo;
        });

        // Assign the combined photos back to photos2
        let finalPhotos2 = combinedPhotos;

        console.log(photos2); // Output the final array of photos





        let dateStampedPhotos = [];
        let dateStampedPhotos2 = [];













        let filename = null;
        let filename2 = null;
        let filename3 = null;
        let filename4 = null;
        let filename5 = null;
        let filename6 = null;
        let filename7 = null;
        let filename8 = null;
        let filename9 = null;
        let filename10 = null;
        let filename11 = null;
        let filename12 = null;
        let filename13 = null;
        let filename14 = null;



        let filename15 = null;
        let filename16 = null;
        let filename17 = null;
        let filename18 = null;
        let filename19 = null;
        let filename20 = null;
        let filename21 = null;
        let filename22 = null;
        let extraFilename1 = null;
        let extraFilename2 = null;
        let filenameOg = null;














        if (photo) {
            const formData = new FormData()




            filename = crypto.randomUUID() + photo.name
            formData.append("filename", filename)
            formData.append("image", photo)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename = state.logoImg
        }


        if (photoOg) {
            const formData = new FormData()




            filenameOg = crypto.randomUUID() + photoOg.name
            formData.append("filename", filenameOg)
            formData.append("image", photoOg)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filenameOg = state.ogImg
        }






        if (photo2) {
            const formData = new FormData()




            filename2 = crypto.randomUUID() + photo2.name
            formData.append("filename", filename2)
            formData.append("image", photo2)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename2 = state.landingHeroImg
        }












        if (photo3) {
            const formData = new FormData()




            filename3 = crypto.randomUUID() + photo3.name
            formData.append("filename", filename3)
            formData.append("image", photo3)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename3 = state.tilesImg1
        }

        if (photo4) {
            const formData = new FormData()




            filename4 = crypto.randomUUID() + photo4.name
            formData.append("filename", filename4)
            formData.append("image", photo4)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename4 = state.tilesImg2

        }



        if (photo5) {
            const formData = new FormData()




            filename5 = crypto.randomUUID() + photo5.name
            formData.append("filename", filename5)
            formData.append("image", photo5)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename5 = state.landingImg3
        }



        if (photo6) {
            const formData = new FormData()




            filename6 = crypto.randomUUID() + photo6.name
            formData.append("filename", filename6)
            formData.append("image", photo6)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename6 = state.teamBannerBackgroundImg
        }



        if (photo7) {
            const formData = new FormData()




            filename7 = crypto.randomUUID() + photo7.name
            formData.append("filename", filename7)
            formData.append("image", photo7)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename7 = state.whySellHeroImg
        }



        if (photo8) {
            const formData = new FormData()




            filename8 = crypto.randomUUID() + photo8.name
            formData.append("filename", filename8)
            formData.append("image", photo8)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename8 = state.whySellPanelsImg1
        }

        if (photo9) {
            const formData = new FormData()




            filename9 = crypto.randomUUID() + photo9.name
            formData.append("filename", filename9)
            formData.append("image", photo9)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename9 = state.whySellPanelsImg2
        }

        if (photo10) {
            const formData = new FormData()




            filename10 = crypto.randomUUID() + photo10.name
            formData.append("filename", filename10)
            formData.append("image", photo10)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename10 = state.whySellPanelsImg3
        }




        if (photo12) {
            const formData = new FormData()




            filename12 = crypto.randomUUID() + photo12.name
            formData.append("filename", filename12)
            formData.append("image", photo12)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename12 = state.contactMainImg
        }



        if (photo13) {
            const formData = new FormData()




            filename13 = crypto.randomUUID() + photo13.name
            formData.append("filename", filename13)
            formData.append("image", photo13)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename13 = state.reviewHeroImg
        }






        if (photo15) {
            const formData = new FormData()




            filename15 = crypto.randomUUID() + photo15.name
            formData.append("filename", filename15)
            formData.append("image", photo15)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename15 = state.aboutHeroImg
        }



        if (photo16) {
            const formData = new FormData()




            filename16 = crypto.randomUUID() + photo16.name
            formData.append("filename", filename16)
            formData.append("image", photo16)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename16 = state.aboutMainImg
        }


        if (photo17) {
            const formData = new FormData()




            filename17 = crypto.randomUUID() + photo17.name
            formData.append("filename", filename17)
            formData.append("image", photo17)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename17 = state.stampDutyHeroImg
        }


        if (photo18) {
            const formData = new FormData()




            filename18 = crypto.randomUUID() + photo18.name
            formData.append("filename", filename18)
            formData.append("image", photo18)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename18 = state.contactHeroImg
        }

        if (photo19) {
            const formData = new FormData()




            filename19 = crypto.randomUUID() + photo19.name
            formData.append("filename", filename19)
            formData.append("image", photo19)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename19 = state.mwhyLetHeroImg
        }



        if (photo20) {
            const formData = new FormData()




            filename20 = crypto.randomUUID() + photo20.name
            formData.append("filename", filename20)
            formData.append("image", photo20)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename20 = state.whyLetPanelsImg1
        }




        if (photo21) {
            const formData = new FormData()




            filename21 = crypto.randomUUID() + photo21.name
            formData.append("filename", filename21)
            formData.append("image", photo21)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename21 = state.whyLetPanelsImg2
        }


        if (photo22) {
            const formData = new FormData()




            filename22 = crypto.randomUUID() + photo22.name
            formData.append("filename", filename22)
            formData.append("image", photo22)

            await request('/upload/image', "POST", {}, formData, true)
        }
        else {
            filename22 = state.whyLetPanelsImg3
        }

























        try {


            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;

            data = await request(`/website/${website[0]?._id}`, 'PUT', options, {
                ...state,




                logoImg: filename,
                ogImg: filenameOg,
                landingHeroImg: filename2,
                tilesImg1: filename3,
                tilesImg2: filename4,
                tilesImg3: filename5,
                teamBannerBackgroundImg: filename6,
                whySellHeroImg: filename7,
                whySellPanelsImg1: filename8,
                whySellPanelsImg2: filename9,
                whySellPanelsImg3: filename10,
                aboutHeroImg: filename15,
                aboutMainImg: filename16,
                stampDutyHeroImg: filename17,
                contactHeroImg: filename18,
                contactMainImg: filename12,
                wyLetHeroImg: filename19,
                whyLetPanelsImg1: filename20,
                whyLetPanelsImg2: filename21,
                whyLetPanelsImg3: filename22,
                reviewHeroImg: filename13,
                slideshowGallery: [...finalPhotos2]








            })






            setNewPhotos([]);
            setNewPhotos2([]);


            fetchWebsite();
            setOpen("")
            setPreloader(false)
            alert("The website has been successfully updated!")
            window.scrollTo({ top: 0, behavior: 'smooth' });




        } catch (error) {
            console.error(error)

            setPreloader(false)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }






    const handleUploadPhotoEdit2 = (e) => {
        const files = Array.from(e.target.files);

        setPhotos2([...files, ...photos2]);


    };



    const deletePhoto2 = (photo) => {


        setPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };









    const [preloader, setPreloader] = useState(false)



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos2);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos2(updatedItems);
    };











    return (
        <div className={classes.listPropertyWrapper} style={open === "" ? { width: viewportWidth < 600 ? "65%" : "40%" } : { width: viewportWidth < 600 ? "70%" : "50%" }} onClick={(e) => e.stopPropagation()}>
            <h2>EDIT WEBSITE</h2>

            {preloader ? (
                <div style={{ width: "auto" }}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) :

                (


                    <form onSubmit={handleWebsite}>


                        <h3 className={classes.scripTitle} id="home" onClick={() => { handleOpen("home") }}>HOME</h3>





                        <div className={classes.openWrapper} style={open === "home" ? { height: "auto", opacity: "1", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>









                            <div className={classes.inputs}>
                                <label>
                                    Website Title:
                                    <input type="text" className={classes.titleInput2} value={state.landingHeroTitle} name='landingHeroTitle' onChange={handleState} />
                                </label>
                                <label>
                                    Tab Title:
                                    <input type="text" className={classes.titleInput2} value={state.tabTitle} name='tabTitle' onChange={handleState} />
                                </label>
                                <label>
                                    Tiles Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.tilesTitle1} name='tilesTitle1' onChange={handleState} />
                                </label>

                                <label>
                                    Tiles Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.tilesTitle2} name='tilesTitle2' onChange={handleState} />
                                </label>
                                <label>
                                    Tiles  Title 3:
                                    <input type="text" className={classes.titleInput2} value={state.tilesTitle3} name='tilesTitle3' onChange={handleState} />
                                </label>


                                <label>
                                    Tiles Header Title:
                                    <input type="text" className={classes.titleInput2} value={state.tilesHeaderTitle} name='tilesHeaderTitle' onChange={handleState} />
                                </label>





                                <label>
                                    Team Banner Title:
                                    <input type="text" className={classes.titleInput2} value={state.teamBannerTitle} name='teamBannerTitle' onChange={handleState} />
                                </label>

                                <label>
                                    Copyright Message:
                                    <input type="text" className={classes.titleInput2} value={state.copyrightMessage} name='copyrightMessage' onChange={handleState} />
                                </label>




                                <label>
                                Landing Hero Button Type:
                                    <select className={classes.titleInput2} value={state.landingHeroButtonType} name='landingHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>







                                <label>
                                    Tiles Header Button Type:
                                    <select className={classes.titleInput2} value={state.tilesHeaderButtonType} name='tilesHeaderButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Tiles Button Type 1:
                                    <select className={classes.titleInput2} value={state.tilesButtonType1} name='tilesButtonType1' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Tiles Button Type 2:
                                    <select className={classes.titleInput2} value={state.tilesButtonType2} name='tilesButtonType2' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Tiles Button Type 3:
                                    <select className={classes.titleInput2} value={state.tilesButtonType3} name='tilesButtonType3' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>








                                <label>
                                    Team Banner Button Type:
                                    <select className={classes.titleInput2} value={state.teamBannerButtonType} name='teamBannerButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Tiles Button Type:
                                    <select className={classes.titleInput2} value={state.tilesButtonType} name='tilesButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>



                            </div>




                            <div className={classes.photoSection}>



                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="photoEdit">Logo Img</label>
                                    <input
                                        id="photoEdit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto(e.target.files[0]) }}
                                    />

                                    {photo === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.logoImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload}>
                                    <label className={classes.uniButton} htmlFor="photoEditOgImg">Link Img</label>
                                    <input
                                        id="photoEditOgImg"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhotoOg(e.target.files[0]) }}
                                    />

                                    {photoOg === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.ogImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photoOg)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>






                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo3Edit">Tiles Image 1</label>
                                    <input
                                        id="photo3Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto3(e.target.files[0]) }}
                                    />

                                    {photo3 === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.tilesImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo3)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo4Edit">Tiles Image 2</label>
                                    <input
                                        id="photo4Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto4(e.target.files[0]) }}
                                    />

                                    {photo4 === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.tilesImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo4)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo5Edit">Tiles Image 3</label>
                                    <input
                                        id="photo5Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto5(e.target.files[0]) }}
                                    />

                                    {photo5 === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.tilesImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo5)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>









                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo6Edit">Team Banner Background Img</label>
                                    <input
                                        id="photo6Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto6(e.target.files[0]) }}
                                    />

                                    {photo6 === "" ? (


                                        <img src={`https://katteochapi.onrender.com/images/${state?.teamBannerBackgroundImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />



                                    ) : (
                                        <img src={URL.createObjectURL(photo6)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>
                            </div>










                            <h6>Landing Hero Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.landingHeroDesc} name='landingHeroDesc' ref={(el) => (refs.current['landingHeroDesc'] = el)} onChange={handleState} />
                            <h6>Tiles Header Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.tilesHeaderDesc} name='tilesHeaderDesc' ref={(el) => (refs.current['tilesHeaderDesc'] = el)} onChange={handleState} />
                            <h6>Tiles Desc 1</h6>
                            <textarea className={classes.scrip} type="text" value={state.tilesDesc1} name='tilesDesc1' ref={(el) => (refs.current['tilesDesc1'] = el)} onChange={handleState} />
                            <h6>Tiles Desc 2</h6>
                            <textarea className={classes.scrip} type="text" value={state.tilesDesc2} name='tilesDesc2' ref={(el) => (refs.current['tilesDesc2'] = el)} onChange={handleState} />
                            <h6>Tiles Desc 3</h6>
                            <textarea className={classes.scrip} type="text" value={state.tilesDesc3} name='tilesDesc3' ref={(el) => (refs.current['tilesDesc3'] = el)} onChange={handleState} />
                            <h6>Valuation Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.valuationDesc} name='valuationDesc' ref={(el) => (refs.current['valuationDesc'] = el)} onChange={handleState} />
                            <h6>Team Banner Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.teamBannerDesc} name='teamBannerDesc' ref={(el) => (refs.current['teamBannerDesc'] = el)} onChange={handleState} />
                        



                            <button className={classes.formButton2} type="submit">Update Home Page</button>

                        </div>


                        <h3 className={classes.scripTitle} id="portfolio" onClick={() => { handleOpen("portfolio") }}>WHY LET</h3>






                        <div className={classes.openWrapper} style={open === "portfolio" ? { height: "auto", opacity: "1", maxHeight: "14000px", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>



                                <label>
                                    Why Let Hero Title:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetHeroTitle} name='whyLetHeroTitle' onChange={handleState} />
                                </label>

                                <label>
                                    Why Let Columns Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetColumnsTitle1} name='whyLetColumnsTitle1' onChange={handleState} />
                                </label>

                                <label>
                                    Why Let Columns Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetColumnsTitle2} name='whyLetColumnsTitle2' onChange={handleState} />


                                </label>



                                <label>
                                    Why Let Columns Title 3:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetColumnsTitle3} name='whyLetColumnsTitle3' onChange={handleState} />
                                </label>

                                <label>
                                    Why Let Panels Header Title:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetPanelsHeaderTitle} name='whyLetPanelsHeaderTitle' onChange={handleState} />

                                </label>


                                <label>
                                    Why Let Panels Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetPanelsTitle1} name='whyLetPanelsTitle1' onChange={handleState} />


                                </label>



                                <label>
                                    Why Let Panels Title 2:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetPanelsTitle2} name='whyLetPanelsTitle2' onChange={handleState} />
                                </label>

                                <label>
                                    Why Let Panels Title 3:
                                    <input type="text" className={classes.titleInput2} value={state.whyLetPanelsTitle3} name='whyLetPanelsTitle3' onChange={handleState} />

                                </label>







                                <label>
                                    Why Let Panels Bottom Button Type:
                                    <select className={classes.titleInput2} value={state.whyLetPanelsBottomButtonType} name='whyLetPanelsBottomButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Let Hero Button Type:
                                    <select className={classes.titleInput2} value={state.whyLetHeroButtonType} name='whyLetHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Let Panels Button 1:
                                    <select className={classes.titleInput2} value={state.whyLetPanelsButton1} name='whyLetPanelsButton1' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Let Panels Button 2:
                                    <select className={classes.titleInput2} value={state.whyLetPanelsButton2} name='whyLetPanelsButton2' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Why Let Panels Button 3:
                                    <select className={classes.titleInput2} value={state.whyLetPanelsButton3} name='whyLetPanelsButton3' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Let Columns Button Type 1:
                                    <select className={classes.titleInput2} value={state.whyLetColumnsButtonType1} name='whyLetColumnsButtonType1' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Why Let Columns Button Type 2:
                                    <select className={classes.titleInput2} value={state.whyLetColumnsButtonType2} name='whyLetColumnsButtonType2' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Let Columns Button Type 3:
                                    <select className={classes.titleInput2} value={state.whyLetColumnsButtonType3} name='whyLetColumnsButtonType3' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>





                            </div>

                            <div className={classes.photoSection}>


                        

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo20Edit">Why Let Panels Img 1</label>

                                    <input
                                        id="photo20Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto20(e.target.files[0]) }}
                                    />

                                    {photo20 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whyLetPanelsImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo20)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo21Edit">Why Let Panels Img 2</label>

                                    <input
                                        id="photo21Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto21(e.target.files[0]) }}
                                    />

                                    {photo21 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whyLetPanelsImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo21)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo22Edit">Why Let Panels Img 3</label>

                                    <input
                                        id="photo22Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto22(e.target.files[0]) }}
                                    />

                                    {photo22 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whyLetPanelsImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo22)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>




                            <h6>Why Let Columns Desc 1:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetColumnsDesc1} name='whyLetColumnsDesc1' ref={(el) => (refs.current['whyLetColumnsDesc1'] = el)} onChange={handleState} />

                            <h6>Why Let Columns Desc 2:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetColumnsDesc2} name='whyLetColumnsDesc2' ref={(el) => (refs.current['whyLetColumnsDesc2'] = el)} onChange={handleState} />


                            <h6>Why Let Columns Desc 3:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetColumnsDesc3} name='whyLetColumnsDesc3' ref={(el) => (refs.current['whyLetColumnsDesc3'] = el)} onChange={handleState} />


                            <h6>Why Let Hero Desc</h6>

                            <textarea className={classes.scrip} type="text" value={state.whyLetHeroDesc} name='whyLetHeroDesc' ref={(el) => (refs.current['whyLetHeroDesc'] = el)} onChange={handleState} />



                            <h6>Why Let Panels Desc 1:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetPanelsDesc1} name='whyLetPanelsDesc1' ref={(el) => (refs.current['whyLetPanelsDesc1'] = el)} onChange={handleState} />

                            <h6>Why Let Panels Desc 2:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetPanelsDesc2} name='whyLetPanelsDesc2' ref={(el) => (refs.current['whyLetPanelsDesc2'] = el)} onChange={handleState} />


                            <h6>Why Let Panels Desc 3:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetPanelsDesc3} name='whyLetPanelsDesc3' ref={(el) => (refs.current['whyLetPanelsDesc3'] = el)} onChange={handleState} />

                            <h6>Why Let Panels Header Desc:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetPanelsHeaderDesc} name='whyLetPanelsHeaderDesc' ref={(el) => (refs.current['whyLetPanelsHeaderDesc'] = el)} onChange={handleState} />



                            <h6>Why Let Panels Bottom Caption:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whyLetPanelsBottomCaption} name='whyLetPanelsBottomCaption' ref={(el) => (refs.current['whyLetPanelsBottomCaption'] = el)} onChange={handleState} />



                            <button className={classes.formButton2} type="submit">Update Why Let</button>

                        </div>


                        <h3 className={classes.scripTitle} id="about" onClick={() => { handleOpen("about") }}>WHY SELL</h3>




                        <div className={classes.openWrapper} style={open === "about" ? { height: "auto", maxHeight: "14000px", opacity: "1", } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>



                                <label>
                                    Why Sell Hero Title:
                                    <input type="text" className={classes.titleInput2} value={state.whySellHeroTitle} name='whySellHeroTitle' onChange={handleState} />
                                </label>

                                

                                <label>
                                    Why Sell Panels Header Title:
                                    <input type="text" className={classes.titleInput2} value={state.whySellPanelsHeaderTitle} name='whySellPanelsHeaderTitle' onChange={handleState} />

                                </label>


                                <label>
                                    Why Sell Panels Title 1:
                                    <input type="text" className={classes.titleInput2} value={state.whySellPanelsTitle1} name='whySellPanelsTitle1' onChange={handleState} />


                                </label>



                                <label>
                                    Why Sell Panels Title2:
                                    <input type="text" className={classes.titleInput2} value={state.whySellPanelsTitle2} name='whySellPanelsTitle2' onChange={handleState} />
                                </label>

                                <label>
                                    Why Sell Panels Title 3:
                                    <input type="text" className={classes.titleInput2} value={state.whySellPanelsTitle3} name='whySellPanelsTitle3' onChange={handleState} />

                                </label>







                                <label>
                                    Why Sell Panels Bottom Button Type:
                                    <select className={classes.titleInput2} value={state.whySellPanelsBottomButtonType} name='whySellPanelsBottomButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Sell Hero Button Type:
                                    <select className={classes.titleInput2} value={state.whySellHeroButtonType} name='whySellHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Sell Panels Button 1:
                                    <select className={classes.titleInput2} value={state.whySellPanelsButton1} name='whySellPanelsButton1' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Why Sell Panels Button 2:
                                    <select className={classes.titleInput2} value={state.whySellPanelsButton2} name='whySellPanelsButton2' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>


                                <label>
                                    Why Sell Panels Button 3:
                                    <select className={classes.titleInput2} value={state.whySellPanelsButton3} name='whySellPanelsButton3' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                              






                            </div>

                            <div className={classes.photoSection}>


                            

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo8Edit">Why Sell Panels Img 1</label>

                                    <input
                                        id="photo8Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto8(e.target.files[0]) }}
                                    />

                                    {photo8 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whySellPanelsImg1}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo8)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo9Edit">Why Sell Panels Img 2</label>

                                    <input
                                        id="photo9Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto9(e.target.files[0]) }}
                                    />

                                    {photo9 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whySellPanelsImg2}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo9)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo10Edit">Why Sell Panels Img 3</label>

                                    <input
                                        id="photo10Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto10(e.target.files[0]) }}
                                    />

                                    {photo10 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.whySellPanelsImg3}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo10)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>




                            <h6> Why Sell Hero Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellHeroDesc} name='whySellHeroDesc' ref={(el) => (refs.current['whySellHeroDesc'] = el)} onChange={handleState} />



                            <h6>Why Sell Panels Desc 1:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellPanelsDesc1} name='whySellPanelsDesc1' ref={(el) => (refs.current['whySellPanelsDesc1'] = el)} onChange={handleState} />

                            <h6>Why Sell Panels Desc 2:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellPanelsDesc2} name='whySellPanelsDesc2' ref={(el) => (refs.current['whySellanelsDesc2'] = el)} onChange={handleState} />


                            <h6>Why Sell Panels Desc 3:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellPanelsDesc3} name='whySellPanelsDesc3' ref={(el) => (refs.current['whySellPanelsDesc3'] = el)} onChange={handleState} />

                            <h6>Why Sell Panels Header Desc:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellPanelsHeaderDesc} name='whySellPanelsHeaderDesc' ref={(el) => (refs.current['whySellPanelsHeaderDesc'] = el)} onChange={handleState} />



                            <h6>Why Sell Panels Bottom Caption:</h6>
                            <textarea className={classes.scrip} type="text" value={state.whySellPanelsBottomCaption} name='whySellPanelsBottomCaption' ref={(el) => (refs.current['whySellPanelsBottomCaption'] = el)} onChange={handleState} />



                            <button className={classes.formButton2} type="submit">Update Why Sell</button>

                        </div>





                        <h3 className={classes.scripTitle} id="do" onClick={() => { handleOpen("do") }}>ABOUT</h3>











                        <div className={classes.openWrapper} style={open === "do" ? { height: "auto", maxHeight: "14000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>
                                <label>
                                    About Hero Title:
                                    <input type="text" className={classes.titleInput2} value={state.aboutHeroTitle} name='aboutHeroTitle' onChange={handleState} />

                                </label>

                                <label>
                                    About Main Title:
                                    <input type="text" className={classes.titleInput2} value={state.aboutMainTitle} name='aboutMainTitle' onChange={handleState} />

                                </label>

                           

                                <label>
                                    About Hero Button Type:
                                    <select className={classes.titleInput2} value={state.aboutHeroButtonType} name='aboutHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>
                         

                            </div>






                            <div className={classes.photoSection}>






                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo15Edit">aboutHeroImg</label>

                                    <input
                                        id="photo15Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto15(e.target.files[0]) }}
                                    />

                                    {photo15 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.aboutHeroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo15)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo16Edit">aboutMainImg:</label>

                                    <input
                                        id="photo16Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto16(e.target.files[0]) }}
                                    />

                                    {photo16 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.aboutMainImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo16)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>

                            </div>


                            <h6>About Hero Desc</h6>

                            <textarea className={classes.scrip} type="text" value={state.aboutHeroDesc} name='aboutHeroDesc' ref={(el) => (refs.current['aboutHeroDesc'] = el)} onChange={handleState} />


                            <h6>About Main Desc</h6>

                            <textarea className={classes.scrip} type="text" value={state.aboutMainDesc} name='aboutMainDesc' ref={(el) => (refs.current['aboutMainDesc'] = el)} onChange={handleState} />



                            <h6>About Main Paragraph 1</h6>

                            <textarea className={classes.scrip} type="text" value={state.aboutMainParagraph1} name='aboutMainParagraph1' ref={(el) => (refs.current['aboutHeroDesc'] = el)} onChange={handleState} />


                            <h6>About Main Paragraph 2</h6>

                            <textarea className={classes.scrip} type="text" value={state.aboutMainParagraph2} name='aboutMainParagraph2' ref={(el) => (refs.current['aboutMainDesc'] = el)} onChange={handleState} />



                            <h6>About Main Paragraph 3</h6>

                            <textarea className={classes.scrip} type="text" value={state.aboutMainParagraph3} name='aboutMainParagraph3' ref={(el) => (refs.current['aboutMainDesc'] = el)} onChange={handleState} />




                            <button className={classes.formButton2} type="submit">Update About</button>

                        </div>


                        <h3 className={classes.scripTitle} id="work" onClick={() => { handleOpen("work") }}>STAMP DUTY CALCULATOR</h3>




                        <div className={classes.openWrapper} style={open === "work" ? { height: "auto", maxHeight: "14000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0", }}>

                            <div className={classes.inputs}>
                                <label>
                                    Stamp Duty Hero Title:
                                    <input type="text" className={classes.titleInput2} value={state.stampDutyHeroTitle} name='stampDutyHeroTitle' onChange={handleState} />

                                </label>

                                <label>
                                    Stamp Duty Main Title:
                                    <input type="text" className={classes.titleInput2} value={state.stampDutyMainTitle} name='stampDutyMainTitle' onChange={handleState} />

                                </label>




                                <label>
                                    Stamp Duty Hero Button Type:
                                    <select className={classes.titleInput2} value={state.stampDutyHeroButtonType} name='stampDutyHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                                <label>
                                    Stamp Duty Main Button Type:
                                    <select className={classes.titleInput2} value={state.stampDutyMainButtonType} name='stampDutyMainButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                            </div>



                            <div className={classes.photoSection}>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo17Edit">Stamp Duty Hero Img:</label>

                                    <input
                                        id="photo17Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto17(e.target.files[0]) }}
                                    />

                                    {photo17 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.stampDutyHeroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo17)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>



                            <h6>Stamp Duty Hero Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.stampDutyHeroDesc} name='stampDutyHeroDesc' ref={(el) => (refs.current['stampDutyHeroDesc'] = el)} onChange={handleState} />

                            <h6>Stamp Duty Main Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.stampDutyMainDesc} name='stampDutyMainDesc' ref={(el) => (refs.current['stampDutyMainDesc'] = el)} onChange={handleState} />

                            <button className={classes.formButton2} type="submit">Update Stamp Duty</button>





                        </div>


                        <h3 className={classes.scripTitle} id="review" onClick={() => { handleOpen("review") }}>REVIEWS</h3>






                        <div className={classes.openWrapper} style={open === "review" ? { height: "auto", maxHeight: "14000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0", }}>

                            <div className={classes.inputs}>

                                <label>
                                    Review Hero Title:
                                    <input type="text" className={classes.titleInput2} value={state.reviewHeroTitle} name='reviewHeroTitle' onChange={handleState} />

                                </label>
                                <label>
                                    Review Main Title:
                                    <input type="text" className={classes.titleInput2} value={state.reviewMainTitle} name='reviewMainTitle' onChange={handleState} />

                                </label>


                                <label>
                                    Review Hero Button Type:
                                    <select className={classes.titleInput2} value={state.reviewHeroButtonType} name='reviewHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>

                            </div>



                            <div className={classes.photoSection}>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo13Edit"> Review Hero Img:</label>

                                    <input
                                        id="photo13Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto13(e.target.files[0]) }}
                                    />

                                    {photo13 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.reviewHeroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo13)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>



                            <h6>Review Hero Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.reviewHeroDesc} name='reviewHeroDesc' ref={(el) => (refs.current['reviewHeroDesc'] = el)} onChange={handleState} />
                            <button className={classes.formButton2} type="submit">Update Reviews</button>

                        </div>



                        <h3 className={classes.scripTitle} id="contact" onClick={() => { handleOpen("contact") }}>CONTACT</h3>






                        <div className={classes.openWrapper} style={open === "contact" ? { height: "auto", maxHeight: "14000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>

                            <div className={classes.inputs}>

                                <label>
                                    Address (Main):
                                    <input type="text" className={classes.titleInput2} value={state.addressLine} name='addressLine' onChange={handleState} />

                                </label>



                                <label>
                                    Email:
                                    <input type="text" className={classes.titleInput2} value={state.contactEmail} name='contactEmail' onChange={handleState} />

                                </label>

                                <label>
                                    Number:
                                    <input type="text" className={classes.titleInput2} value={state.contactNumber} name='contactNumber' onChange={handleState} />

                                </label>


                                <label>
                                    Contact Main Title:
                                    <input type="text" className={classes.titleInput2} value={state.contactMainTitle} name='contactMainTitle' onChange={handleState} />
                                </label>

                                <label>
                                    Contact Main Bottom Title:
                                    <input type="text" className={classes.titleInput2} value={state.contactMainBottomTitle} name='contactMainBottomTitle' onChange={handleState} />
                                </label>

                                <label>
                                    LinkedIn Link:
                                    <input type="text" className={classes.titleInput2} value={state.linkedInLink} name='linkedInLink' onChange={handleState} />
                                </label>
                                <label>
                                    Facebook Link:
                                    <input type="text" className={classes.titleInput2} value={state.facebookLink} name='facebookLink' onChange={handleState} />
                                </label>

                                <label>
                                    Instagram Link:
                                    <input type="text" className={classes.titleInput2} value={state.instaLink} name='instaLink' onChange={handleState} />
                                </label>



                                <label>
                                    Contact Hero Button Type:
                                    <select className={classes.titleInput2} value={state.contactHeroButtonType} name='contactHeroButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>



                            </div>




                            <div className={classes.photoSection}>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo18Edit">Contact Hero Img:</label>

                                    <input
                                        id="photo18Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto18(e.target.files[0]) }}
                                    />

                                    {photo18 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.contactHeroImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo18)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>


                                <div className={classes.newUpload} >
                                    <label className={classes.uniButton} htmlFor="photo12Edit">Contact Main Img:</label>

                                    <input
                                        id="photo12Edit"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) => { setPhoto12(e.target.files[0]) }}
                                    />

                                    {photo12 === "" ? (
                                        <img src={`https://katteochapi.onrender.com/images/${state?.contactMainImg}`} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    ) : (
                                        <img src={URL.createObjectURL(photo12)} style={{ width: '80px', height: '80px' }} alt="Uploaded" />
                                    )}
                                </div>



                            </div>


                            <h6>Contact Main Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.contactMainDesc} name='contactMainDesc' ref={(el) => (refs.current['contactMainDesc'] = el)} onChange={handleState} />

                            <button className={classes.formButton2} type="submit">Update Contact</button>





                        </div>


                        <h3 className={classes.scripTitle} id="banner" onClick={() => { handleOpen("banner") }}>PROPERTIES (BANNER)</h3>


                        <div className={classes.openWrapper} style={open === "banner" ? { height: "auto", maxHeight: "14000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>


                            <div className={classes.inputs}>

                                <label>
                                    Properties Banner Title:
                                    <input type="text" className={classes.titleInput2} value={state.propertiesBannerTitle} name='propertiesBannerTitle' onChange={handleState} />
                                </label>



                                <label>
                                    Properties Banner Button Type:
                                    <select className={classes.titleInput2} value={state.propertiesBannerButtonType} name='propertiesBannerButtonType' onChange={handleState}>
                                        <option value="">Select an option</option> {/* Non-option */}
                                        <option value="Valuation">Valuation</option>
                                        <option value="WhyLet">WhyLet</option>
                                        <option value="WhySell">WhySell</option>
                                        <option value="OurTeam">OurTeam</option>
                                        <option value="About">About</option>
                                        <option value="Properties">Properties</option>
                                    </select>
                                </label>



                            </div>



                            <h6>Properties Banner Desc</h6>
                            <textarea className={classes.scrip} type="text" value={state.propertiesBannerDesc} name='propertiesBannerDesc' ref={(el) => (refs.current['propertiesBannerDesc'] = el)} onChange={handleState} />



                            <button className={classes.formButton2} type="submit">Update Properties (Banner)</button>



                        </div>


                        <h3 className={classes.scripTitle} id="gallery" onClick={() => { handleOpen("gallery") }}>SLIDESHOW GALLERY</h3>

                        <div className={classes.openWrapper} style={open === "gallery" ? { height: "auto", maxHeight: "100000px", opacity: "1", paddingTop: "2vh" } : { maxHeight: "0", opacity: "0" }}>
                            <div className={classes.photoSection}>


                                <div className={classes.upload} >

                                    <label htmlFor="photosEdit2" className={classes.plusButtonDiv}>
                                        <IoMdAddCircle style={{ marginBottom: "8vh" }} className={classes.plusButton} />

                                    </label>
                                    <input id="photosEdit2" type="file" style={{ display: 'none' }} multiple onChange={handleUploadPhotoEdit2} />

                                    <DragDropContext onDragEnd={onDragEnd} >
                                        <Droppable droppableId="panelItems">
                                            {(provided) => (
                                                <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                                    {photos2?.map((photo, index) => (

                                                        <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                            {(provided) => (

                                                                <div className={classes.keyword} ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps} key={index}>

                                                                    <AiOutlineClose onClick={() => {
                                                                        deletePhoto2(photo)
                                                                    }} className={classes.removeIcon} />
                                                                    <img
                                                                        src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://katteochapi.onrender.com/images/${photo}`}
                                                                        style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                        alt={`Uploaded ${index + 1}`}
                                                                    />

                                                                </div>
                                                            )}



                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>



                                </div>


                            </div>


                            <button className={classes.formButton2} type="submit">Update Slideshow Gallery</button>
                        </div>































                    </form>



                )


            }











        </div >
    )
}

export default AdminWebsite