import React, { useEffect, useState } from 'react'
import classes from './reviews.module.css'
import { AiFillStar } from 'react-icons/ai';
import { request } from '../../util/fetchAPI'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal';
import { FaGoogle } from "react-icons/fa";

const Reviews = () => {


  const reduxData = useSelector((state) => state.website);

  const [allReviews, setAllReviews] = useState({
    html_attributions: [],
    result: {
      reviews: [
        {
          author_name: "Kamila Żebrowska",
          author_url: "https://www.google.com/maps/contrib/107029681447433290198/reviews",
          language: "en",
          original_language: "en",
          profile_photo_url: "https://lh3.googleusercontent.com/a-/AD_cMMR-ITTQcw_zmJGrAiNAuAyug6LZG_AHVr6tgSZul1rWh1I=s128-c0x00000000-cc-rp-mo",
          rating: 5,
          relative_time_description: "2 months ago",
          text: "What a great experience and customer service, would highly recommend!",
          time: 1687384263,
          translated: false
        }
      ]
    },
    status: "OK"
  });

  useEffect(() => {
    const fetchAllReviews = async () => {
      const data = await request(`/api/place-details`, 'GET');
      setAllReviews(data);
     
    };
    fetchAllReviews();
  }, []);





  return (

    <div className={classes.container}>
      <div className={classes.wrapper}>
        <h3>{reduxData.data.reviewHeroTitle}</h3>
        <p>{reduxData.data.reviewHeroDesc}</p>
       

        {allReviews.result.reviews?.length > 0 && allReviews.result.reviews.map((review, index) => (





            <div className={classes.rect}  key={index}>
              <div className={classes.top}>
                <p className={classes.name}>{allReviews.result.reviews[index].author_name}</p>
                <div className={classes.stars}>



                {[...Array(allReviews.result.reviews[index].rating)].map((e, i) => {
      return <AiFillStar key={i} className={classes.star} />;
    })}



                </div>

              </div>
              <div className={classes.bottom}>
                <p>{allReviews.result.reviews[index].text}</p>
              </div>
            </div>







   



        ))}









      </div>
    </div>
  )
}

export default Reviews