import React, { useEffect, useState } from "react";
import classes from "./tsAndCs.module.css";
import { request } from '../../util/fetchAPI';




const TsAndCs = () => {

    const [termsData, setTermsData] = useState([]);


    useEffect(() => {


        const fetchAllTerms = async () => {
            const data = await request(`/termsAndConditions/getAll`, 'GET');
            console.log(data)
            setTermsData(data[0]);
        };


        fetchAllTerms();

    }, [])





    return (
        <div className={classes.privacyNotice}>



            {

              termsData.contents?.map((object, index) => (


                    <>




                        {
                            object.elementType === "Title" && (

                                <h2>{object.content[0].contentString}</h2>

                            )


                        }
                        {
                            object.elementType === "SubTitle" && (

                                <h3>{object.content[0].contentString}</h3>

                            )

                        }
                        {
                            object.elementType === "Paragraph" && (
                                <p>{object.content[0].contentString}</p>


                            )

                        }
                        {
                            object.elementType === "NumberedList" && (

                                <div style={{maxWidth: "90%"}}> {object.content.map((contentItem, index) => (

                                    <h4>{index}. {contentItem.contentString}</h4>


                                ))

                                }
                                </div >

                            )

                        }


                        {object.elementType === "List" && (

                            <ul style={{maxWidth: "90%"}}> {object.content.map((contentItem, index) => (

                                <li >{contentItem.contentString}</li>


                            ))

                            }
                            </ul>

                        )}



                    </>





                ))


            }







        </div>
    );
}

export default TsAndCs;