import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { request } from '../../util/fetchAPI';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';

import classes from './agentDetail.module.css';


const AgentDetail = () => {




  const { token, user } = useSelector((state) => state.auth);
  const [userDetail, setUserDetail] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [desc, setDesc] = useState('');
  const [success, setSuccess] = useState(false);
  const [propertiesWithOwner, setPropertiesWithOwner] = useState([]);
  const { id } = useParams();
  const formRef = useRef();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false)
  const isLoading = Object.keys(userDetail).length === 0;


  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const data = await request(`/displayUsers/find/${id}`, 'GET');
        setUserDetail(data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDetails();
  }, [id]);



  const handleCloseForm = () => {
    setShowForm(false);
    setShowModal2(false);
    setDesc('');
  };





  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);


  const [inputValue, setInputValue] = useState(userDetail?.email);
  const isInputDisabled = true; // Set this to true or false based on your condition

  const handleChange = (e) => {
    if (!isInputDisabled) {
      setInputValue(e.target.value);
    }
  };


  const handleProperties = () => {

    //navigate to properties
    navigate(`/properties?propType=&contType=&search=`)

  }


  const handleAgents = () => {

    //navigate to properties
    navigate(`/contact`)

  }



  return (
    <div className={classes.container}>



      {isLoading ? (
        <p className={classes.loader}>Loading...</p> // Placeholder for when data is being fetched
      ) : (<>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <img alt="" src={`https://katteochapi.onrender.com/images/${userDetail?.profileImg}`} />
          </div>
          <div className={classes.right}>
            <div className={classes.banner}>
              <h3 className={classes.title}>{`${userDetail?.username}`}</h3>


            </div>

            <p className={classes.desc}>
              <span>{userDetail?.role}</span>
            </p>

            <p>
              {userDetail?.desc}

            </p>

            <div className={classes.photosDiv}>

             

              <button onClick={handleProperties} className={classes.contactOwner} id={classes.contactOwnerId}>
                See Properties
              </button>

              <button onClick={handleAgents} className={classes.contactOwner} >
                All Agents
              </button>

            </div>





          </div>
        </div>

      </>)}


















    </div>
  );
};

export default AgentDetail;