import React, {useEffect, useState, useRef} from 'react'
import classes from "./reviewStrip.module.css"
import {  AiFillStar } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { request } from '../../util/fetchAPI'

const ReviewStrip = () => {



  const [allReviews, setAllReviews] = useState({    html_attributions: [],
  result: {
      reviews: [
          {
              author_name: "Kamila Żebrowska",
              author_url: "https://www.google.com/maps/contrib/107029681447433290198/reviews",
              language: "en",
              original_language: "en",
              profile_photo_url: "https://lh3.googleusercontent.com/a-/AD_cMMR-ITTQcw_zmJGrAiNAuAyug6LZG_AHVr6tgSZul1rWh1I=s128-c0x00000000-cc-rp-mo",
              rating: 5,
              relative_time_description: "2 months ago",
              text: "What a great experience and customer service, would highly recommend!",
              time: 1687384263,
              translated: false
          }
      ]
  },
  status: "OK"});








useEffect(() => {
    const fetchAllReviews = async () => {
      const data = await request(`/api/place-details`, 'GET');
      setAllReviews(data);
     
    };
    fetchAllReviews();
  }, []);


  useEffect(() => {

    console.log(allReviews)

  }, [allReviews])


  const navigate = useNavigate()



  const handleReview = () => {
    //navigate to properties
   navigate(`/reviews`)
}




const descRef = useRef([]);



useEffect(() => {
  const appearOptions = {
    threshold: 0.45,
  };

  const appearOnScroll = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(classes.appear);
        observer.unobserve(entry.target);
      }
    });
  }, appearOptions);

  descRef.current.forEach((desc) => {
    appearOnScroll.observe(desc);
  });

  

  return () => {
    appearOnScroll.disconnect();
  };
}, [allReviews]);





  return (
    <div className={classes.container}>
      { allReviews.result.reviews?.length > 0? (
     <div className={classes.wrapper} ref={(el) => (descRef.current[0] = el)}>


   {allReviews.result.reviews.slice(0,3).map((property, index) => (

    <div className={classes.square} key={index}>
  <h5>{allReviews.result.reviews[index].author_name}</h5>
  <div className={classes.stars}>
    {[...Array(allReviews.result.reviews[index].rating)].map((e, i) => {
      return <AiFillStar key={i} className={classes.star} />;
    })}
  </div>
  <p>
    "{`${allReviews.result.reviews[index].text.split(' ').slice(0, 10).join(' ')}${
      allReviews.result.reviews[index].text.split(' ').length > 22 ? '...' : ''
    }`}"
  </p>
</div>

  ))}




</div> ): (

<div className={classes.wrapper} ref={(el) => (descRef.current[0] = el)}>



</div>

)}
       

        <button onClick={handleReview} className={classes.btn}>READ OUR REVIEWS </button>
    </div>
  )
}

export default ReviewStrip