import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import classes from "./slide.module.css";
import { AiFillStar } from 'react-icons/ai';
import Carousel2 from "../velocity/Velocity";

function Slideshow(props) {

  const descRef = useRef([]);

  useEffect(() => {
   
    const appearOptions = {
      threshold: 0.45,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    }, [props]);

    return () => {
      appearOnScroll.disconnect();
    };
  }, []);

  const navigate = useNavigate();

  const handleValuation = () => {
    navigate(`/valuation`);
  };

  return (
    <>
     
        <div className={classes.absolute} >
          <div className={classes.left}>
            {props.jsxProp}
        
        
          </div>
        </div>
   
   
        <div className={classes.window} >
          <Carousel2 />
        </div>

    </>
  );
}

export default Slideshow;