import React, { useEffect, useRef } from 'react'
import classes from './whyKStripe.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const WhyKStrip = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const navigate = useNavigate()


  const descRef = useRef([]);

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
  };
  preloadImage(PF + "knobg2.webp");



  useEffect(() => {
    const appearOptions = {
      threshold: 0.3,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });



    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


  const reduxData = useSelector((state) => state.website);


  const handleSearch = () => {
    navigate(`/properties`);
  };

  const handleContact = () => {
    navigate(`/contact`);
  };

  const handleAbout = () => {
    navigate(`/about`);
  };


  const handleWhySell = () => {
    navigate(`/whysell`);
  };


  const handleWhyLet = () => {
    navigate(`/whylet`);
  };


  const handleValuation = () => {
    navigate(`/valuation`);
  };




  return (
    <div className={classes.container}>
      <div className={classes.wrapper} ref={(el) => (descRef.current[0] = el)}>
        <h2> {reduxData.data.tilesHeaderTitle}</h2>
        <p>{reduxData.data.tilesHeaderDesc}</p>
        {reduxData.data.tilesHeaderButtonType === "Properties" && (
          <button onClick={handleSearch} className={classes.buttonK} >
            OUR PORTFOLIO
          </button>
        )


        }


        {reduxData.data.tilesHeaderButtonType === "OurTeam" && (
          <button onClick={handleContact} className={classes.buttonK} >
            MEET THE TEAM
          </button>
        )


        }

        {reduxData.data.tilesHeaderButtonType === "About" && (

          <button onClick={handleAbout} className={classes.buttonK} >
            OUR MISSION
          </button>
        )


        }

        {reduxData.data.tilesHeaderButtonType === "WhyLet" && (
          <button onClick={handleWhyLet} className={classes.buttonK} >
            RENT
          </button>

        )

        }

        {reduxData.data.tilesHeaderButtonType === "WhySell" && (
          <button onClick={handleWhySell} className={classes.buttonK} >
            SELL
          </button>

        )


        }

        {reduxData.data.tilesHeaderButtonType === "Valuation" && (
          <button onClick={handleValuation} className={classes.buttonK} >
            Get a Valuation
          </button>

        )


        }
      </div>
    </div>
  )
}

export default WhyKStrip