import React, { useEffect, useState } from 'react'
import { request } from '../../../util/fetchAPI';
import { useDispatch, useSelector } from 'react-redux'
import classes from "./adminDisplayUser.module.css"
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal';
import AdminDisplayUserEdit from '../AdminDisplayUserEdit/AdminDisplayUserEdit';










const AdminDisplayUser = () => {




    const { user, token } = useSelector((state) => state.auth)
    const [allUsers, setAllUsers] = useState([]);
    const [profilePhoto, setProfilePhoto] = useState("")
    const [state, setState] = useState({})
    const [showForm2, setShowForm2] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedUser, setSelectedUser] = useState({})


    const fetchAllUsers = async () => {
        const data = await request(`/displayUsers/getAll`, 'GET');
        setAllUsers(data);
    };



    useEffect(() => {
        fetchAllUsers();

    }, [])



    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue })
    }


    const handleSelectedUser = async (id) => {
        const data = await request(`/displayUsers/find/${id}`, "GET");
        setSelectedUser(data);


    }



    const handleCreateDisplayUser = async (e) => {
        e.preventDefault();


        let filename1 = "";





        if (profilePhoto) {
            const formData = new FormData();

            filename1 = crypto.randomUUID() + profilePhoto.name;
            formData.append("filename", filename1);
            formData.append("image", profilePhoto);

            await request('/upload/image', "POST", {}, formData, true);
        }




        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state,
                profileImg: filename1
            };

            await request('/displayUsers', 'POST', options, requestData);


            setState({})
            setProfilePhoto("")

            fetchAllUsers();

            alert("Your display user was sucessfully created!");
        } catch (error) {
            console.error(error);
            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };




    const handleCloseForm = () => {
        setShowForm(false)
        setShowForm2(false)

        setProfilePhoto(null)
        setState({})
    }



    const deleteProperty = async () => {
        try {
            await request(`/displayUsers/${selectedUser._id}`, 'DELETE', { Authorization: `Bearer ${token}` });


            fetchAllUsers();
            alert("Your property has been successfully deleted!")

        } catch (error) {

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };



    const confirmDelete = () => {

        deleteProperty();


        setShowForm(false);


    };




    return (
        <div className={classes.container}>










            <h2>Display User List</h2>


            <div className={classes.properties} style={{ display: "flex" }} >


                {allUsers.map((user) => (
                    <div key={user._id} className={classes.property}  >
                        <div className={classes.imgContainer}>
                            <img src={`https://katteochapi.onrender.com/images/${user.profileImg}`} alt="" />
                        </div>

                        <div className={classes.details}>




                            <h3 className={classes.propName}>{user.username}</h3>

                            <div className={classes.selectorDiv}>

                                <button className={classes.btnEdit} onClick={() => {
                                    setShowForm2(true);
                                    handleSelectedUser(user._id)
                                }}>Edit</button>

                                <button className={classes.btnDelete} onClick={() => {
                                    setShowForm(true);
                                    handleSelectedUser(user._id)
                                }}>Delete</button>
                            </div>



                        </div>



                        <Modal
                            isOpen={showForm}
                            onRequestClose={() => setShowForm(false)}
                            contentLabel="Delete User Modal"
                            className="modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: "999999999999999999999999999999999999",
                                },
                                content: {
                                    width: '50%',
                                    height: '200px',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: "#2d2c2c",
                                    border: "0",
                                    borderRadius: "20px",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch", // For iOS Safari
                                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                                },
                            }}
                        >
                            <div className={classes.delWrapper}>
                                <AiOutlineClose onClick={() => {
                                    setShowForm(false)
                                }} className={classes.removeIcon} />
                                <h2>Are you sure you want to delete this property?</h2>

                                <div className={classes.btnDels}>
                                    <button className={classes.btnDelete2} onClick={confirmDelete}>Delete</button>
                                    <button className={classes.btnEdit2} onClick={() => setShowForm(false)}>Cancel</button>
                                </div>
                            </div>
                        </Modal>


                        <Modal
                            isOpen={showForm2}
                            onRequestClose={() => setShowForm2(false)}
                            contentLabel="Delete User Modal"
                            className="modal"

                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: "999",
                                },
                                content: {
                                    width: '55%',
                                    height: "80vh",
                                    position: 'absolute',
                                    margin: '0',
                                    padding: '0',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: "#2d2c2c",
                                    border: "0",
                                    borderRadius: "20px",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch", // For iOS Safari
                                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                                },
                            }}
                        >

                            <AiOutlineClose onClick={() => {
                                setShowForm2(false);
                            }} className={classes.removeIcon} />


                            <AdminDisplayUserEdit property={selectedUser} closeModal={handleCloseForm} fetchFunction={fetchAllUsers} />


                        </Modal>



                    </div>
                ))}
            </div>


            <div className={classes.listPropertyForm}>
                <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                    <h2>Create Display User</h2>
                    <form onSubmit={handleCreateDisplayUser}>

                        <div className={classes.inputs}>
                            <label>
                                <span>Username:</span>
                                <input type="text" placeholder='Title...' name='username' onChange={handleState} />

                            </label>

                            <label>
                                <span>Role:</span>
                                <input type="text" placeholder='Role...' name='role' onChange={handleState} />

                            </label>



                        </div>

                        <h3 className={classes.scripTitle}>User description:</h3>

                        <textarea className={classes.scrip} placeholder='Description...' name='desc' onChange={handleState} />

                        <h3 className={classes.scripTitle}>Photos:</h3>
                        <div className={classes.types2}>


                            <div className={classes.upload} >
                                <label className={classes.btnUpload2} htmlFor="profilePhoto">Profile Image </label>
                                <input
                                    id="profilePhoto"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => setProfilePhoto(e.target.files[0])}
                                />


                                {profilePhoto && <img src={URL.createObjectURL(profilePhoto)} style={{ width: "75px", height: "75px", borderRadius: "50%", objectFit: "cover" }} alt="Uploaded" />}

                            </div>



                        </div>





                        <button className={classes.formButton2} type="submit">Create Display User</button>
                    </form>



                </div>

            </div>
        </div>
    )
}

export default AdminDisplayUser