import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import classes from "./dashboard.module.css"
import { request } from '../../util/fetchAPI';
import { register, logout } from '../../redux/authSlice'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal';
import DashboardEdit from '../dashboardEdit/DashboardEdit';
import AdminWebsite from '../admin/adminWebsite/AdminWebsite';
import AdminDisplayUser from '../admin/AdminDisplayUser/AdminDisplayUser';
import AdminTerms from '../admin/AdminTerms/AdminTerms';
import AdminPrivacy from '../admin/AdminPrivacy/AdminPrivacy';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoMdAddCircle } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";


const PF = process.env.REACT_APP_PUBLIC_FOLDER;


const preloadImage = (url) => {
    const img = new Image();
    img.src = url;

    return img;


};


const background = preloadImage(PF + "background1.jpg");


const Dashboard = () => {




    const [allProperties, setAllProperties] = useState([]);




    const { user, token } = useSelector((state) => state.auth)


    const [state, setState] = useState({});

    const [state3, setState3] = useState(user);

    const [showForm2, setShowForm2] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showForm3, setShowForm3] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState("");
    const [newPassword, setNewPassword] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [selectedProp, setSelectedProp] = useState("");
    const [selectedCont, setSelectedCont] = useState("");
    const [selectedBuild, setSelectedBuild] = useState("");

    const [keywords, setKeywords] = useState([]);
    const [keywords2, setKeywords2] = useState([]);
    const [currentWord, setCurrentWord] = useState("");
    const [currentWord2, setCurrentWord2] = useState("");


    const [currentPassword, setCurrentPassword] = useState("")


    const [photo, setPhoto] = useState("")
    const [photo3, setPhoto3] = useState("")
    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);
    const [photo4, setPhoto4] = useState(undefined)


    const [isLoading, setIsLoading] = useState(false);






    const handleLoad = () => {
        setTimeout(() => {

            setIsLoading(true)

        }, [2000]);
    }












    useEffect(() => {
        Modal.setAppElement('#root');

    }, []);






    const handleCloseForm = () => {
        setShowForm(false)
        setShowForm2(false)
        setSelectedEvent("")
        setPhoto(null)
        setState({})
    }


    const handleLogout = () => {
        dispatch(logout());
        navigate("/employee-signin");
    }

    const deletePhoto = (photo) => {


        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };


    const deletePhoto2 = (photo) => {


        setPhotos2((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };


    const deleteKeyword = (keyword) => {

        setKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
        setState({ ...state, keywordArray: [...keywords] })
    };

    const deleteKeyword2 = (keyword) => {

        setKeywords2((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
        setState({ ...state, closestStations: [...keywords2] })
    };



    const handleNewPassword = (e) => {
        setNewPassword(e.target.value)
    }


    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }


    const handleWord2 = (e) => {
        setCurrentWord2(e.target.value)
    }

    const handleAddKeyword = (e) => {
        e.preventDefault();


        if (currentWord) {
            setKeywords([...keywords, currentWord]);
            setState({ ...state, keywordArray: [...keywords, currentWord] })
            setCurrentWord("");
        }
    };

    const handleAddKeyword2 = (e) => {
        e.preventDefault();


        if (currentWord2) {
            setKeywords2([...keywords2, currentWord2]);
            setState({ ...state, closestStations: [...keywords2, currentWord2] })
            setCurrentWord2("");
        }
    };
    const handleState = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setState({ ...state, [name]: newValue, propType: selectedProp, contType: selectedCont, buildType: selectedBuild })
    }


    const handleCurrentPassword = (e) => {


        setCurrentPassword(e.target.value)

    }




    const handleState3 = (e) => {
        setState3(prev => {
            return { ...prev, [e.target.name]: e.target.value }
        })

    }
    const fetchAllProperties = async () => {
        const data = await request(`/property/getAll`, 'GET');
        setAllProperties(data);
    };

    const deleteProperty = async () => {
        try {
            await request(`/property/${selectedEvent._id}`, 'DELETE', { Authorization: `Bearer ${token}` });

            setSelectedEvent("")
            fetchAllProperties();
            alert("Your property has been successfully deleted!")

        } catch (error) {

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };

    const confirmDelete = () => {

        deleteProperty();


        setShowForm(false);


    };

    const handleOpenForm3 = () => {
        setShowForm3(true);


    }


    const handleListProperty = async (e) => {
        e.preventDefault();
        setPreloader(true)

        let dateStampedPhotos = [];
        let dateStampedPhotos2 = [];
        let filename1 = "";
        let filename2 = "";



        if (photos.length > 0) {
            const uploadPromises = photos.map(async (photo, index) => {
                if (photo) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + photo.name;

                    dateStampedPhotos.push(filename);
                    formData.append("filename", filename);
                    formData.append("image", photo);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }
        }

        if (photos2.length > 0) {
            const uploadPromises2 = photos2.map(async (photo, index) => {
                if (photo) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + photo.name;

                    dateStampedPhotos2.push(filename);
                    formData.append("filename", filename);
                    formData.append("image", photo);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises2);
            } catch (error) {
                console.error(error);
            }
        }

        if (photo) {
            const formData = new FormData();

            filename1 = crypto.randomUUID() + photo.name;
            formData.append("filename", filename1);
            formData.append("image", photo);

            await request('/upload/image', "POST", {}, formData, true);
        }

        if (photo3) {

            if (photo3 === "") {

                filename2 = ""



            }
            const formData1 = new FormData();

            filename2 = crypto.randomUUID() + photo3.name;
            formData1.append("filename", filename2); // Modified field name
            formData1.append("image", photo3); // Modified field name

            await request('/upload/image', "POST", {}, formData1, true);
        }



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state,
                photoArray: dateStampedPhotos,
                img: filename1,
                energyImg: filename2,
                floorPlanImgs: dateStampedPhotos2
            };

            await request('/property', 'POST', options, requestData);

            fetchAllProperties();

            setPhotos([]);
            setPhotos2([]);
            alert("Your property was sucessfully created!");
            setPreloader(false)
        } catch (error) {
            console.error(error);
            alert("Property creation Failed! Please try again or try signing out and signing in to the Dashboard.");
            setPreloader(false)
        }
    };




    useEffect(() => {

        fetchAllProperties();
    }, []);


    const editMenuRef = useRef(null);



    const handleSelectedEvent = async (id) => {




        setSelectedEvent("")
        const data = await request(`/property/find/${id}`, "GET");
        setSelectedEvent(data);


    }


    useEffect(() => {
        if (editMenuRef.current && selectedEvent !== "" && showForm2 === true) {
            editMenuRef.current.scrollIntoView({ behavior: 'smooth' });
        }

    }, [selectedEvent])



    const handleUploadPhoto = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);
    };

    const handleUploadPhoto2 = (e) => {
        const files = Array.from(e.target.files);

        setPhotos2([...photos2, ...files]);
    };




    const handlePropChange = (event) => {
        const { value } = event.target;
        setSelectedProp(value);

    };

    const handleContChange = (event) => {
        const { value } = event.target;
        setSelectedCont(value);

    };

    const handleBuildChange = (event) => {
        const { value } = event.target;
        setSelectedBuild(value);


    };





    useEffect(() => {
        setState({ ...state, propType: selectedProp, contType: selectedCont, buildType: selectedBuild })


    }, [selectedCont, selectedBuild, selectedProp]);



    const handleSubmit = async (e) => {

        e.preventDefault()




        try {

            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }


            let filename = null
            if (photo4 !== undefined) {
                const formData = new FormData()
                filename = crypto.randomUUID() + photo4.name
                formData.append("filename", filename)
                formData.append('image', photo4)

                await request('/upload/image', "POST", {}, formData, true)



                const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, profileImg: filename, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)




            } else {


                const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)



            }









        } catch (error) {
            console.error(error)
            alert("Please enter your current password before making changes to your profile. Or signin again to refresh your authentication token");
        }


    }
    const reduxData = useSelector((state) => state.website);




    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos(updatedItems);
    };


    const onDragEnd2 = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(photos2);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setPhotos2(updatedItems);
    };




    const [preloader, setPreloader] = useState(false)




    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "#fff",


    };



    const handleEditPreload = (bool) => {

        setPreloader(bool)
    }




    return (
        <div className={classes.container} >
            <div className={classes.abso} style={{ backgroundImage: `url(${background.src})`, objectFit: "cover" }}>


            </div>
            {user ? (

                <div className={classes.wrapper}>

<div className={classes.topBar}>
                                <div className={classes.section}>
                                    <img alt="logo" src={`https://katteochapi.onrender.com/images/${reduxData.data.logoImg}`} />
                                    <h1>Real Estate Agents</h1>

                                </div>
                                <div className={classes.section2}>
                                    <p className={classes.logout} onClick={handleLogout}>Log Out</p>
                                    <p className={classes.logout} onClick={handleOpenForm3}>
                                        {user.username}
                                    </p>



                                </div>






                            </div>

                    {preloader ? (
                        <div style={{ width: "auto" }}>
                            <ClipLoader
                                color={color}
                                loading={true}
                                cssOverride={override}
                                size={300}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    ) :



                        (<>

                           

                            <AdminWebsite />

                            <h2>Properties</h2>



                            <div className={classes.listPropertyForm} >
                                <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                    <h2>Create Property</h2>
                                    <form onSubmit={handleListProperty}>

                                        <div className={classes.inputs}>
                                            <label>
                                                Title:
                                                <input type="text" placeholder='Title...' name='title' onChange={handleState} />

                                            </label>
                                            <label>
                                                Council:
                                                <input type="text" placeholder='Council Tax Band...' name='councilTaxBand' onChange={handleState} />

                                            </label>
                                            <label>
                                                Area:
                                                <input type="text" placeholder='Area...' name='area' onChange={handleState} />
                                            </label>
                                            <label>
                                                Postcode:
                                                <input type="text" placeholder='Postcode...' name='postcode' onChange={handleState} />

                                            </label>
                                            <label>
                                                Price (please provide "£"):
                                                <input type="text" placeholder='Price...' name='price' onChange={handleState} />

                                            </label>
                                            <label>
                                                Square Metres:
                                                <input type="number" placeholder='Sq.metres...' name='sqmeters' onChange={handleState} />
                                            </label>
                                            <label>
                                                Beds:
                                                <input type="number" placeholder='Beds...' step={1} name='beds' onChange={handleState} />
                                            </label>
                                            <label>
                                                Bathrooms:
                                                <input type="number" placeholder='Bathrooms...' step={1} name='bathrooms' onChange={handleState} />
                                            </label>










                                        </div>

                                        <h3 className={classes.scripTitle}>Property description:</h3>

                                        <textarea className={classes.scrip} placeholder='Description...' name='desc' onChange={handleState} />

                                        <h3 className={classes.scripTitle}>Property types:</h3>

                                        <div className={classes.types}>
                                            <div className={classes.type}>
                                                <label>Property Type:</label>
                                                <div className={classes.radio}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="propType"
                                                            value="commercial"
                                                            checked={selectedProp === 'commercial'}
                                                            onChange={handlePropChange}
                                                        />
                                                        Commercial
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="propType"
                                                            value="private"
                                                            checked={selectedProp === 'private'}
                                                            onChange={handlePropChange}
                                                        />
                                                        Private
                                                    </label>

                                                </div>

                                            </div>
                                            <div className={classes.type}>
                                                <label>Building Type:</label>
                                                <div className={classes.radio}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="buildType"
                                                            value="flat"
                                                            checked={selectedBuild === 'flat'}
                                                            onChange={handleBuildChange}
                                                        />
                                                        Flat
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="buildType"
                                                            value="house"
                                                            checked={selectedBuild === 'house'}
                                                            onChange={handleBuildChange}
                                                        />
                                                        House
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="buildType"
                                                            value="office"
                                                            checked={selectedBuild === 'office'}
                                                            onChange={handleBuildChange}
                                                        />
                                                        Office
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="buildType"
                                                            value="retail"
                                                            checked={selectedBuild === 'retail'}
                                                            onChange={handleBuildChange}
                                                        />
                                                        Retail
                                                    </label>


                                                </div>

                                            </div>
                                            <div className={classes.type}>
                                                <label>Contract Type:</label>
                                                <div className={classes.radio}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="contType"
                                                            value="let"
                                                            checked={selectedCont === 'let'}
                                                            onChange={handleContChange}
                                                        />
                                                        Let
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="contType"
                                                            value="sale"
                                                            checked={selectedCont === 'sale'}
                                                            onChange={handleContChange}
                                                        />
                                                        Sale
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="contType"
                                                            value="to let"
                                                            checked={selectedCont === 'to let'}
                                                            onChange={handleContChange}
                                                        />
                                                        To Let
                                                    </label>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="contType"
                                                            value="sold"
                                                            checked={selectedCont === 'sold'}
                                                            onChange={handleContChange}
                                                        />
                                                        Sold
                                                    </label>

                                                </div>

                                            </div>

                                            <label className={classes.garden}>
                                                Garden?
                                                <input
                                                    type="checkbox"
                                                    name="garden"
                                                    checked={state.garden}
                                                    onChange={handleState}
                                                />
                                            </label>

                                        </div>
                                        <h3 className={classes.scripTitle}>Photos:</h3>
                                        <div className={classes.types2}>


                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photo">Main Image </label>
                                                <input
                                                    id="photo"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto(e.target.files[0])}
                                                />


                                                {photo && <img src={URL.createObjectURL(photo)} style={{ width: "200px", height: "200px", objectFit: "cover" }} alt="Uploaded" />}

                                            </div>

                                            <div className={classes.upload}>
                                                <label className={classes.btnUpload2} htmlFor="photo3">Energy Rating </label>
                                                <input
                                                    id="photo3"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => setPhoto3(e.target.files[0])}
                                                />
                                                <div onClick={() => { setPhoto3("") }} type="button" className={classes.btnUpload}>Empty Energy Img</div>


                                                {photo3 && <img src={URL.createObjectURL(photo3)} style={{ width: "200px", height: "200px", objectFit: "cover" }} alt="Uploaded" />}
                                            </div>

                                            <div className={classes.upload}>
                                                <label className={classes.btnUpload2} htmlFor="photos">Floor Plan Imgs</label>
                                                <input
                                                    id="photos"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    onChange={handleUploadPhoto}
                                                />



                                                <DragDropContext onDragEnd={onDragEnd} >
                                                    <Droppable droppableId="panelItems">
                                                        {(provided) => (
                                                            <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                                                {photos?.map((photo, index) => (

                                                                    <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                                        {(provided) => (

                                                                            <div className={classes.keyword} ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps} key={index}>

                                                                                <AiOutlineClose onClick={() => {
                                                                                    deletePhoto(photo)
                                                                                }} className={classes.removeIcon} />
                                                                                <img
                                                                                    src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://katteochapi.onrender.com/images/${photo}`}
                                                                                    style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                                    alt={`Uploaded ${index + 1}`}
                                                                                />

                                                                            </div>
                                                                        )}



                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>

                                            </div>

                                            <div className={classes.upload} >
                                                <label className={classes.btnUpload2} htmlFor="photos2">Side images  </label>
                                                <input
                                                    id="photos2"
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    onChange={handleUploadPhoto2}
                                                />










                                                <DragDropContext onDragEnd={onDragEnd2} >
                                                    <Droppable droppableId="panelItems">
                                                        {(provided) => (
                                                            <div className={classes.keywords} ref={provided.innerRef} {...provided.droppableProps}>
                                                                {photos2?.map((photo, index) => (

                                                                    <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                                                        {(provided) => (

                                                                            <div className={classes.keyword} ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps} key={index}>

                                                                                <AiOutlineClose onClick={() => {
                                                                                    deletePhoto2(photo)
                                                                                }} className={classes.removeIcon} />
                                                                                <img
                                                                                    src={photo instanceof Blob ? URL.createObjectURL(photo) : `https://katteochapi.onrender.com/images/${photo}`}
                                                                                    style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                                                                    alt={`Uploaded ${index + 1}`}
                                                                                />

                                                                            </div>
                                                                        )}



                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>


                                            </div>

                                        </div>

                                        <h3 className={classes.scripTitle}>Keywords:</h3>


                                        <div className={classes.keywordDiv}>
                                            <input
                                                className={classes.keyInput}
                                                type="text"
                                                placeholder="Keyword..."
                                                name="keyword"
                                                value={currentWord || ""}
                                                onChange={handleWord}
                                            />
                                            <button type="button" className={classes.photoDel} onClick={handleAddKeyword}>
                                                Add Keyword
                                            </button>
                                        </div>
                                        <div className={classes.keywords2}>
                                            {keywords.map((keyword) => (
                                                <div key={keyword} className={classes.keyword}  >
                                                    <p>{keyword}</p>
                                                    <button className={classes.photoDel} onClick={() => {
                                                        deleteKeyword(keyword)

                                                    }}>Delete</button>
                                                </div>
                                            ))}
                                        </div>



                                        <div className={classes.keywordDiv}>
                                            <input

                                                type="text"
                                                placeholder="Add stations..."
                                                name="closestStations"
                                                value={currentWord2 || ""}
                                                onChange={handleWord2}
                                            />
                                            <button type="button" className={classes.photoDel} onClick={handleAddKeyword2}>
                                                Add Keyword
                                            </button>
                                        </div>

                                        <div className={classes.keywords2}>
                                            {keywords2.map((keyword) => (
                                                <div key={keyword} className={classes.keyword} >
                                                    <p>{keyword}</p>
                                                    <button className={classes.photoDel} onClick={() => {
                                                        deleteKeyword2(keyword)

                                                    }}>Delete</button>
                                                </div>
                                            ))}
                                        </div>





                                        <button className={classes.formButton2} type="submit">List Property</button>
                                    </form>



                                </div>
                            </div>

                            <h2>Current Properties</h2>

                            {isLoading === false && <div className={classes.preloader}>
                                <p className={classes.loader}>Loading...</p>
                            </div>}

                            <div className={classes.properties} style={isLoading === true ? { display: "flex" } : { display: "none" }} >


                                {allProperties.map((property) => (
                                    <div key={property._id} className={classes.property}  >
                                        <div className={classes.imgContainer}>
                                            <img src={`https://katteochapi.onrender.com/images/${property.img}`} onLoad={handleLoad} alt="" />
                                        </div>

                                        <div className={classes.details}>




                                            <h3 className={classes.propName}>{property.title}</h3>

                                            <div className={classes.selectorDiv}>

                                                <button className={classes.btnEdit} onClick={() => {
                                                    setShowForm2(true);
                                                    handleSelectedEvent(property._id)
                                                }}>Edit</button>

                                                <button className={classes.btnDelete} onClick={() => {
                                                    setShowForm(true);
                                                    handleSelectedEvent(property._id)
                                                }}>Delete</button>
                                            </div>



                                        </div>


                                    </div>
                                ))}
                            </div>

                            {selectedEvent !== "" && <div className={classes.listPropertyForm} ref={editMenuRef} >
                                <DashboardEdit property={selectedEvent} closeModal={handleCloseForm} fetchFunction={fetchAllProperties} preloaderFunction={handleEditPreload} />
                            </div>}

                            {selectedEvent !== "" && (<button onClick={() => {
                                setSelectedEvent("")
                            }} className={classes.formButton2}>Clear Property Edit</button>)}

                            <AdminDisplayUser />




                            <div className={classes.editorContainer}>

                                <AdminTerms />
                                <AdminPrivacy />

                            </div>




                            <h5 className={classes.claimer}>Employee Dashboard and associated website are property of Kateooh Ltd. 2023 </h5>
                        </>)}

                    {showForm3 && (


                        <Modal
                            isOpen={showForm3}
                            onRequestClose={() => setShowForm3(false)}
                            contentLabel="Delete User Modal"
                            className="modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: "999999999999999999999999999999999999",
                                },
                                content: {
                                    width: '55%',
                                    height: '80vh',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: "0",
                                    padding: "0",
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: "#2d2c2c",
                                    border: "0",
                                    borderRadius: "20px",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch", // For iOS Safari
                                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization

                                },
                            }}
                        >






                            <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                <AiOutlineClose className={classes.removeIcon} onClick={() => { setShowForm3(false) }} />
                                <h2>Edit User</h2>
                                <form onSubmit={handleSubmit}>

                                    <div className={classes.inputs}>
                                        <label>
                                            Username:
                                            <input type="text" name="username" value={state3.username} placeholder='Username' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            Email:
                                            <input type="email" name="email" value={state3.email} placeholder='Email' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            NEW PASSWORD:
                                            <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />
                                        </label>





                                    </div>

                                    <h3 className={classes.scripTitle}>Agent Bio</h3>
                                    <textarea value={state3.desc} className={classes.scrip} name="desc" placeholder='New description' onChange={handleState3} />

                                    <h3 className={classes.scripTitle}>Profile Photo</h3>
                                    <div className={classes.upload2}>
                                        <label className={classes.btnUpload2} htmlFor="photo4Profile">Upload</label>
                                        <input id="photo4Profile" type="file" style={{ display: 'none' }} onChange={(e) => setPhoto4(e.target.files[0])} />


                                        {photo4 ? <div className={classes.userImg}>
                                            <img src={URL.createObjectURL(photo4)} alt="" />
                                        </div> :
                                            <div className={classes.userImg}>
                                                <img src={`https://katteochapi.onrender.com/images/${state3.profileImg}`} alt="profile" /> </div>}
                                    </div>
                                    <h3 className={classes.scripTitle}>Authentication</h3>
                                    <p className={classes.passMessage}>Please enter your <span className={classes.greenText}>CURRENT PASSWORD</span> to make any changes to your profile, including creating a new password.</p>

                                    <div className={classes.inputs}>
                                        <label>
                                            CURRENT PASSWORD:
                                            <input className={classes.currentPass} type="password" placeholder='currentPassword' onChange={handleCurrentPassword} />

                                        </label>

                                    </div>


                                    <button className={classes.formButton2} type="submit">UPDATE</button>

                                </form>

                            </div>

                        </Modal>)
                    }




                    <Modal
                        isOpen={showForm}
                        onRequestClose={() => setShowForm(false)}
                        contentLabel="Delete User Modal"
                        className="modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999999999999999999999999999999999999",
                            },
                            content: {
                                width: '50%',
                                height: '200px',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >
                        <AiOutlineClose onClick={() => {
                            setShowForm(false)
                        }} className={classes.removeIcon} />
                        <div className={classes.delWrapper}>

                            <h2>Are you sure you want to delete this property?</h2>

                            <div className={classes.btnDels}>
                                <button className={classes.btnDelete2} onClick={confirmDelete}>Delete</button>
                                <button className={classes.btnEdit2} onClick={() => setShowForm(false)}>Cancel</button>
                            </div>
                        </div>
                    </Modal>









                </div>) : (<div className={classes.wrapper2}>

                    <div className={classes.topbar}>
                        <div className={classes.section}>
                            <img alt="logo" src={`https://katteochapi.onrender.com/images/${reduxData.data.logoImg}`} />
                            <h1>Real Estate Agents</h1>

                        </div>

                    </div>

                    <h2>Please sign in to use the Kateeoh Employee Dashboard. Click <a className={classes.here} href="/employee-signin">HERE</a></h2>






                </div>


            )}
        </div>
    )
}

export default Dashboard