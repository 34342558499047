import React, { useEffect, useState } from 'react';
import classes from "./stampDutyCalculator.module.css"


function StampDutyCalculator() {
  const [propertyValue, setPropertyValue] = useState(0);
  const [stampDuty, setStampDuty] = useState();
  const [selectedMethod, setSelectedMethod] = useState("next")

  


  useEffect(() => {
    console.log(selectedMethod);
  }, [selectedMethod]);



  const handleMethodChange = (event) => {
    const { value } = event.target;
    setSelectedMethod(value);
    
  };


  function normalStamp(p) {


    let stamp = 0;
    let difference = 0;
    let b1 = 33750;
    let b2 = 57500;


    if (p < 250001) {

      stamp = 0;


    } else if (p >= 250001 && p < 925001) {

      difference = p - 250000;

      stamp = difference * 0.05;


    } else if (p >= 925001 && p < 1500001) {

      difference = p - 925000;

      stamp = (difference * 0.1) + b1;

      

    } else {

      difference = p - 1500000;

      stamp = (difference * 0.12) + b1 + b2;

    }

    return stamp;
  }

  function firstStamp(p) {

    let stamp = 0;
    let difference = 0;

    if(p <= 425000) {

      stamp  = 0;
      
    } else {

      difference = p - 425001;
      stamp = difference * 0.05;


    }

    return stamp;


  }

 

  function calculateStampDuty(propertyValue) {

    let finalStamp = 0;


    if(selectedMethod === "first") {

      if(propertyValue > 625000) {

       finalStamp = normalStamp(propertyValue)
      } else {

        finalStamp =  firstStamp(propertyValue)
      }



    }


   else  if(selectedMethod === "next") {


    finalStamp = normalStamp(propertyValue);


    }

    else  {

      let initialStamp = normalStamp(propertyValue)
      let extraStamp = propertyValue * 0.03;

      finalStamp = initialStamp + extraStamp;


    }
    
  
   
  
    setStampDuty(finalStamp);
  }
  

  return (
    <div className={classes.container} style={{ padding: '20px',   border: '1px solid #ccc', width: "100%", height: '100%', display: "flex",  flexDirection: "column", alignItems: "center"}}>
      <h2 style={{fontSize: "30px"}}>Stamp Duty Tax Calculator</h2>

      <div style={{ marginBottom: '10px', display: "flex", marginTop: "2vh", justifyContent: "space-between", flexDirection:"column", alignItems: "center", gap: "1rem"}}>
        <label htmlFor="propertyValue">Property Value:</label>
        <input  
          className={classes.property}

          id="propertyValue"
          type="number"
          value={propertyValue}
          style={{fontSize: "15px"}}
          onChange={(e) => setPropertyValue(e.target.value)}
        />
                 
  <div className={classes.radio}>
    <label>
      <input
        type="radio"
        name="propType"
        value="first"
        checked={ selectedMethod === 'first'}
        onChange={handleMethodChange}
      />
     First-time buyer
    </label>
    <label>
      <input
        type="radio"
        name="propType"
        value="next"
        checked={selectedMethod === 'next'}
        onChange={handleMethodChange}
      />
    Your next home
    </label>
    <label>
      <input
        type="radio"
        name="propType"
        value="second"
        checked={selectedMethod === 'second'}
        onChange={handleMethodChange}
      />
    Second property
    </label>
  
  </div>
      </div>

      <button className={classes.stampBtn} onClick={() => calculateStampDuty(propertyValue)}>Calculate Stamp Duty</button>

      {stampDuty > 0 && (
        <div style={{ marginTop: '10px', display: "flex", alignItems: "center", flexDirection: "column"}}>
          <p  style={{fontWeight: "bold", textAlign: "center"}}>Stamp Duty Amount: <br/><span style={{color: "green", fontSize: "20px" , textAlign: "center"}}> £{Math.round(stampDuty).toLocaleString("en-US")} </span></p>
        </div>
      )}
      {stampDuty <= 0 && (
        <div style={{ marginTop: '10px' , display: "flex", alignItems: "center", flexDirection: "column" }}>
        <p  style={{fontWeight: "bold", textAlign: "center"}}>  <span style={{color: "green", fontSize: "20px", textAlign: "center"}}>  You will not pay any Stamp Duty </span></p>
      </div>

      )}
    </div>
  );
}

export default StampDutyCalculator;