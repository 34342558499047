import React, { useState } from 'react'
import classes from './signup.module.css'

import { request } from "../../util/fetchAPI"
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { register } from '../../redux/authSlice'


const Signup = () => {


  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [state, setState] = useState({})
  const [photo, setPhoto] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleState = (e) => {
    setState(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })


  }


  const handleSubmit = async (e) => {

    e.preventDefault()

    try {

      let filename = null
      if (photo) {
        const formData = new FormData()
        filename = crypto.randomUUID() + photo.name
        formData.append("filename", filename)
        formData.append('image', photo)

        await request('/upload/image', "POST", {}, formData, true)
      } else {
        return
      }

      const headers = {
        'Content-Type': "application/json"
      }

      const data = await request("/auth/register", "POST", headers, { ...state, profileImg: filename })
      dispatch(register(data))
      navigate("/")
      console.log(data)

    } catch (error) {
      console.error(error)
    }
  }




  return (
    <div style={{ backgroundImage: `url('${PF + "beauty.webp"}')` }} className={classes.container}>


<div className={classes.topBar}>
          <div className={classes.section}>
            <img alt="logo" src={PF + "knobg2.webp"} />
            <h1>Real Estate Agents</h1>

          </div>
        </div>
      <div className={classes.wrapper}>
        
        <h2>User Registration</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" name="username" placeholder='Username' onChange={handleState} required />
          <input type="email" name="email" placeholder='Email' onChange={handleState} required />

          <input type="password" name="password" placeholder='Password' onChange={handleState} required />
          <input type="password" name="passcode" placeholder='Kateeoh passcode...' onChange={handleState} required />
          <textarea className={classes.desc} name="desc" placeholder='Tell me about yourself...' onChange={handleState} />
          <input id="photo" type="file" style={{ display: 'none' }} onChange={(e) => setPhoto(e.target.files[0])} />

          <label className={classes.labels} htmlFor="photo">Upload photo</label>
          <div>
            {photo && <img src={URL.createObjectURL(photo)} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%", objectFit: "cover" }} />}
          </div>

          <button type="submit">Register</button>
          <p>Already have an account? <Link to="/employee-signin" className={classes.sign}>Sign in</Link>
          </p>
        </form>
      </div>
    </div>
  )
}

export default Signup