import React from 'react'
import classes from './footer.module.css'
import { BsLinkedin } from 'react-icons/bs'
import { FaFacebook } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

const Footer = () => {



  
  const reduxData = useSelector((state) => state.website);



  const navigate = useNavigate()

 
  
const handleSearch = () => {
      //navigate to properties
     navigate(`/properties`)
}

const handleContact = () => {
      //navigate to properties
     navigate(`/contact`)
}

const handleAbout = () => {
    //navigate to properties
   navigate(`/about`)
}



const handlePM = () => {
    //navigate to properties
     navigate(`/whylet`)
}

const handleStamp = () => {
    //navigate to properties
     navigate(`/stampduty`)
}

    const handleValuation = () => {
      //navigate to properties
  
     navigate(`/valuation`)
  
    }

    const handleWhyLet = () => {
      //navigate to properties
  
     navigate(`/whylet`)
  
    }

    const handleWhySell = () => {
      //navigate to properties
  
     navigate(`/whysell`)
  
    }


    const handlePrivacy = () => {
      //navigate to properties
  
     navigate(`/privacyNotice`)
  

    }

    const handleTsAndCs = () => {
      //navigate to properties
  
     navigate(`/terms-and-conditions`)
  
    }

  return (
   <footer>
    <div className={classes.wrapper}>
      <div className={classes.col}>
        <span>{reduxData.data.contactNumber} || {reduxData.data.contactEmail}</span>
        <span className={classes.link} onClick={handlePrivacy}>PRIVACY NOTICE</span>
        <span className={classes.link} onClick={handleTsAndCs}>T&C'S</span>
        <div className={classes.flex}>
         

      <a href={reduxData.data.linkedInLink} target='_blank' rel="noreferrer"> 
        <BsLinkedin className={classes.icon} />
      </a>


      <a  href={reduxData.data.facebookLink} target='_blank' rel="noreferrer">
        <FaFacebook className={classes.icon}  />
      </a>




      <a  href={reduxData.data.instaLink} target='_blank' rel="noreferrer">
      < BsInstagram className={classes.icon} />
      </a>



        </div>
        <p className={classes.copy}>COPYRIGHT K PROPERTY LTD 2023</p>
      </div>
      <div className={classes.col}>
        
      <span className={classes.link} onClick={handleWhySell}>SELLING</span>
        <span className={classes.link} onClick={handleWhyLet}>LETTING</span>
        <span className={classes.link} onClick={handlePM}>PROPERTY MANAGEMENT</span>
        <span className={classes.link} onClick={handleAbout}>ABOUT K</span>
        <span className={classes.link} onClick={handleContact}>CONTACT</span>
      </div>
      <div className={classes.col}>
       
        <span className={classes.link} onClick={handleSearch}>FOR SALE</span>
        <span className={classes.link} onClick={handleSearch}>TO LET</span>
        <span className={classes.link} onClick={handleSearch}>AREA GUIDES</span>
        <span className={classes.link} onClick={handleValuation}>GET A FREE VALUATION</span>
        <span className={classes.link} onClick={handleStamp}>STAMP DUTY CALCULATOR</span>
      </div>
    </div>
  </footer>
  )
}

export default Footer