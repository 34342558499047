import React, {useEffect, useState} from "react";
import classes from "./privacyNotice.module.css"
import { request } from '../../util/fetchAPI';

const PrivacyNotice = () => {


  const [privacyData, setPrivacyData] = useState([]);


  useEffect(() => {

  const fetchAllPrivacy = async () => {
    const data = await request(`/privacyPolicy/getAll`, 'GET');
    setPrivacyData(data[0]);
};


fetchAllPrivacy();


}, [])



  
  return (
    <div className={classes.privacyNotice} >


{
privacyData?.contents?.map((object, index) => (


  <>




    {
      object.elementType === "Title" && (

        <h1>{object.content[0].contentString}</h1>

      )


    }
    {
      object.elementType === "SubTitle" && (

        <h2>{object.content[0].contentString}</h2>

      )

    }
    {
      object.elementType === "Paragraph" && (
        <p>{object.content[0].contentString}</p>


      )

    }
    {
      object.elementType === "NumberedList" && (

        <div style={{maxWidth: "90%"}}> {object.content.map((contentItem, index) => (

          <h4>{index}. {contentItem.contentString}</h4>


        ))

        }
        </div>

      )

    }


    {object.elementType === "List" && (

      <ul style={{maxWidth: "90%"}}> {object.content.map((contentItem, index) => (

        <li>{contentItem.contentString}</li>


      ))

      }
      </ul>

    )}



  </>





))


}

      
    </div>
  );
};

export default PrivacyNotice;