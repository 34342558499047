import React, { useEffect, useState, useRef } from 'react';
import Velocity from 'velocity-animate';
import { useDispatch, useSelector } from 'react-redux'


const PF = process.env.REACT_APP_PUBLIC_FOLDER;




const preloadImage = (url) => {
  const img = new Image();
  img.src = url;
};



preloadImage("oldbatch1.jpg")
preloadImage("oldbatch2.jpg")
preloadImage("oldbatch4.jpg")
preloadImage( "oldbatch3.jpg")
preloadImage( "oldbatch7.jpg")
preloadImage("oldbatch5.jpg")
preloadImage( "oldbatch6.jpg")



const Carousel2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselImgRef = useRef(null);
  const [images, setImages] = useState([]);
  const reduxData = useSelector((state) => state.website);

  function generateImageArray(imageFilenames) {
    return imageFilenames.map((filename, index) => ({
      src: "https://katteochapi.onrender.com/images/" + filename,
      alt: 'people chilling',
      animation: {
        translateX: [-20],
        scale: index % 2 === 0 ? [1.2, 1] : [1, 1.2],
        duration: 7000,
      }
    }));
  }

  useEffect(() => {
    if (reduxData.data && reduxData.data.slideshowGallery && reduxData.data.slideshowGallery.length > 0) {
      setImages(generateImageArray(reduxData.data.slideshowGallery));
    }
  }, [reduxData]);

  useEffect(() => {
    if (images.length > 0) {
      console.log(images);
      animate(currentIndex);
    }
  }, [images]);

  useEffect(() => {
    if (images.length > 0) {
      animate(currentIndex);
    }
  }, [currentIndex]);

  const animate = (n) => {
    const imageData = images[n];
    const nextIndex = n === images.length - 1 ? 0 : n + 1;
    carouselImgRef.current.src = imageData.src;

    Velocity.animate(
      carouselImgRef.current,
      imageData.animation,
      {
        duration: imageData.animation.duration,
        easing: "easeInOutSine",
        complete: () => setCurrentIndex(nextIndex)
      }
    );
  };

 

  return (
    <div className="carousel-container" style={{ width: '110%', height: '107%' }}>
      <div className="carousel-image" style={{ height: '100%', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', backgroundColor: "black"}}>
        <img
          ref={carouselImgRef}
          alt="currentImg"
          
          style={{
           
          opacity: "0.6",
            width: '100%',
            height: '100%',
            objectFit: "cover"
            
          }}
        />
      </div>
    </div>
  );
};

export default Carousel2;